import React, { useEffect, useState } from 'react';
import { Div } from 'react-atomize';
import { Anchor, Dropdown } from 'atomize';
import i18n, { getLastLangFromLocalStorage, setCurrentLangToLocalStorage } from '../../i18n';

const DropdownLanguage = ({ countryId }) => {
  // state for open or close the dropdown
  const [showDropdown, setShowDropdown] = useState(false);

  // state for take the country has been selected
  const [currentCountry, setCurrentCountry] = useState(getLastLangFromLocalStorage());

  // info for countries dropdown
  const dropDownCountries = [
    { name: 'country', url: '#!', value: 1, title: 'Pt', id: 'pt' },
    { name: 'country', url: '#!', value: 2, title: 'Es', id: 'es' },
    { name: 'country', url: '#!', value: 4, title: 'En', id: 'en' },
  ];

  // click event for show/hidden the menu dropdown
  const dropdownClick = () => {
    setShowDropdown(!showDropdown);
  };

  const setCurrentLang = (lang) => {
    i18n.changeLanguage(lang);
  };

  useEffect(() => {
    if (countryId !== 0) {
      if (countryId === 4) {
        handleChange('es');
      } else {
        const langKey = dropDownCountries.find((country) => country.value === countryId);
        if (langKey && langKey.id) {
          handleChange(langKey.id);
        }
      }
    }
  }, [countryId]);

  const handleChange = (selectedId) => {
    setCurrentCountry(selectedId);
    setShowDropdown(false);
    setCurrentLang(selectedId);
    setCurrentLangToLocalStorage(selectedId);
  };

  // layout menu dropdown, travel de info countries
  const menuList = (
    <Div>
      {dropDownCountries.map((country) => (
        <Anchor
          key={country.id}
          onClick={() => {
            handleChange(country.id);
          }}
        >
          <Div d="block" p=".5rem 1rem">
            {country.title}
          </Div>
        </Anchor>
      ))}
    </Div>
  );

  return (
    <Dropdown w="fit-content" isOpen={showDropdown} direction="bottomleft" onClick={dropdownClick} menu={menuList}>
      {currentCountry.charAt(0).toUpperCase() + currentCountry.slice(1)}
    </Dropdown>
  );
};

export default DropdownLanguage;
