import React, { useEffect, useState } from 'react';
import { Div } from 'react-atomize';
import { withTranslation } from 'react-i18next';
import ListNotification from '../widgets/ListNotification';
import { useUserState } from '../../context/UserContext';
import { moneyMapping } from '../../constant/countryMap';
import { formatMoney } from '../../utils/money';
import { cryptoCurrenciesKey } from '../../constant/crypto';

// this is the container to list the notifications in home page
const NotificationContainer = ({ t }) => {
  const [kycResult, setKycResult] = useState({
    name: '',
    url: '',
    message: [],
    icon: 'dni.svg',
  });

  const status = {
    New: 'identityVerificationPending',
    Retry: 'identityVerificationPending',
    Incomplete: 'identityVerificationPending',
    Accepted: 'identityVerificationSuccess',
    Rejected: 'identityVerificationRejected',
  };

  const {
    userInfo: { kyc, limits, user },
  } = useUserState();

  const { limitsDeposit, limitsWithdraw, remainingLimitsDeposit, remainingLimitsWithdraw } = limits;
  const countryAsset = moneyMapping[user.countryCode];
  const listLimit = [];

  /* eslint-disable */
  for (const prop in limitsDeposit) {
    if (limitsDeposit[prop]) {
      listLimit.push(
        `${t('account.limit.deposit.' + prop)}  ${countryAsset} ${formatMoney(
          'en-US',
          remainingLimitsDeposit[prop],
          cryptoCurrenciesKey[countryAsset]?.decimal ?? 0
        )}/${formatMoney('en-US', limitsDeposit[prop], cryptoCurrenciesKey[countryAsset]?.decimal ?? 0)}`
      );
    }
  }

  for (const prop in limitsWithdraw) {
    if (limitsWithdraw[prop]) {
      listLimit.push(
        `${t('account.limit.withdraw.' + prop)} ${countryAsset} ${formatMoney(
          'en-US',
          remainingLimitsWithdraw[prop],
          cryptoCurrenciesKey[countryAsset]?.decimal ?? 0
        )}/${formatMoney('en-US', limitsWithdraw[prop], cryptoCurrenciesKey[countryAsset]?.decimal ?? 0)}`
      );
    }
  }

  useEffect(() => {
    if (!Object.keys(kyc).length) {
      return;
    }
    setKycResult({
      name: kyc.status !== 'Accepted' && kyc.status !== 'New' && kyc.status !== 'Rejected' ? t('home.toComplete') : '',
      url: '/kyc/identity-identification',
      message: [`${t('home.identityVerification')} - ${t('account.' + status[kyc.status])}.`],
      icon: 'dni.svg',
    });
  }, [kyc]);
  /* eslint-enable */

  return (
    <Div>
      <ListNotification iconSize="3rem" {...kycResult} />
      <ListNotification iconSize="3rem" title={t('global.limits')} titleBold message={listLimit} icon="limits.svg" />
    </Div>
  );
};

export default withTranslation()(NotificationContainer);
