import { authErrors } from '../error/authError/constants';

export default {
  global: {
    next: 'Siguiente',
    hello: 'Hola',
    change: 'Cambiar',
    limits: 'Límites',
    buy: 'Comprar',
    sell: 'Vender',
    cancelOrder: 'Cancelar orden',
    cancel: 'Cancelar',
    total: 'Total',
    fiat: 'ARS',
    thanks: 'Gracias',
    dni: 'DNI',
    confirm: 'Confirmar',
    accredited: 'Acreditado',
    outstanding: 'Pendiente de pago',
    cancelled: 'Cancelado',
    or: 'O',
    cbuText: 'CBU',
    aliasText: 'Alias',
    name: 'Nombre',
    bank: 'Banco',
    finish: 'Finalizar',
    wallet: 'Billetera',
    network: 'Red',
    memo: 'Memo',
    received: 'Recibido',
    address: 'Dirección',
    search: 'Buscar',
    woman: 'Mujer',
    man: 'Hombre',
    processed: 'Procesado',
    rejected: 'Rechazado',
    expired: 'Expirado',
    pending: 'Pendiente',
    page: 'Pagina',
    of: 'De',
  },
  navbar: {
    home: 'Inicio',
    transactions: 'Comprar / Vender',
    activity: 'Mi actividad',
    account: 'Mi cuenta',
    logOut: 'Cerrar sesión',
  },
  home: {
    activity: 'Mi actividad',
    buy: 'Comprar',
    sell: 'Vender',
    viewAll: 'Ver todas',
    viewLess: 'Ver menos',
    toComplete: 'Completar',
    confirm: 'Confirmar',
    requestIncrease: 'Solicitar aumento',
    dailyLimit: 'Límite diario restante',
    annualLimit: 'Límite anual restante',
    limits: 'Límites',
    identityVerification: 'Verificación de identidad',
    orderN: 'Orden Nº',
    accredited: 'Acreditado',
    needConfirmation: 'solo falta tu confirmación',
  },
  transactions: {
    next: 'Siguiente',
    message1: 'El precio incluye los gastos del procesador de pagos',
    message2: 'Al continuar estas aceptando los términos y condiciones.',
    buy: {
      feeIncluded: 'fees incluídos',
      wantSpend: 'Quiero gastar',
      selectPayment: {
        title: 'Selecciona una forma de pago',
        totalPayment: 'Total a pagar',
        paymentOptionTransfer: 'Transferencia bancaria',
        paymentOptionCash: 'Efectivo - Rapipago',
        processorCharge: 'Cargo del procesador',
        message1: 'Su orden será procesada por un procesador de pagos local.',
        message2: 'Antes de continuar debe aceptar los términos y condiciones y política de privacidad.',
      },
      orderSumary: {
        title: 'Resumen de tu orden de pago',
        cardTitle: 'Tu pedido de Latamex',
        checkData: 'Chequea todos los datos antes de continuar',
        totalSalesOrder: 'Total orden de venta:',
        estimatedCharges: 'Cargos estimados:',
        totalPayment: 'Total a pagar',
        message1:
          'Comisión de cobro informada por el procesador de pagos local elegido en tu jurisdicción. El monto de la comisión será descontado del total a recibir.',
        message2:
          'Su orden será procesada por un procesador de pagos local. Antes de continuar debe aceptar los términos y condiciones y política de privacidad.',
      },
      enterWallet: {
        title: 'Billetera de destino',
        insertWalletAddress: 'Por favor, ingresa la dirección de tu billetera de ',
        toReceive: ' donde quieres recibir tus criptomonedas.',
        placeholderWalletAddress: 'Dirección de billetera',
        placeholderMemo: 'Memo',
        memoDescription:
          'Muchas billeteras requieren que ingreses un Memo/Tag. En caso de que estés seguro de que no lo requiere tilda la opción “no requiere Memo/Tag“. Si completas esta opción erróneamente, tus fondos se pueden perder. Si tienes dudas puedes leer más sobre Memo/Tag en nuestro centro de ayuda.',
        memoTagCheckbox: 'No quiero MEMO/TAG',
      },
      dataTransfer: {
        title: 'Datos para la transferencia',
        message:
          'Por favor realiza una transferencia bancaria desde una cuenta a tu nombre, por el importe exacto e incluye el número de referencia, si tu banco te permite esta opción, para que podamos identificar mejor tu pago. Si tu banco no te permite incluir el número de referencia, puedes ignorarlo.',
        cbuText: 'CBU',
        cbuValue: '1500647100064760309123',
        aliasText: 'Alias',
        aliasValue: 'SETTLE.REBANKING.ARS',
        referenceText: 'Referencia',
        bankName: 'Banco Industrial',
        headline: 'Titular: Settle AR SAS',
        cuit: 'CUIT: 30-71652016-8',
      },
      endProcess: {
        message1: 'Los datos de tu orden fueron recibidos correctamente. Te enviamos un detalle a tu correo.',
        message2:
          'Al momento de verificar la acreditación de tu pago, te enviaremos un correo para que confirmes tu orden con el precio actual.',
        message3:
          'Los pagos recibidos fuera de horario bancario serán procesados cuando el mismo se reanude (Lunes a Viernes - 10hs a 15hs).',
        message4:
          'Tu orden tendrá una validez de 48hs hábiles para ser pagada, una vez transcurrido ese tiempo sin recibir el pago, la orden se cancelará automáticamente.',
      },
      statusOrder: {
        title: 'Consulta el estado de tu orden',
        textDoubt: 'Si tenes dudas sobre el estado de tu órden',
        linkText: 'contactate con el equipo de soporte',
        message1:
          'Ingresá el número de orden (chequalo en los mails que te enviamos) y tu DNI para podes consultar si tu orden está pendiente, completada o rechazada.',
        queryDefault: 'Depósito',
        placeholderOrderNumber: 'Nº de orden',
        checkOrder: 'Consultar orden',
        orderStatus: 'Estado de la orden',
        queryTypeDeposit: 'Depósito',
        queryTypeextraction: 'Extracción',
      },
      confirmOrder: {
        title: 'Confirma tu orden Nº',
        paymentStatus: 'Estado de tu pago',
        yourOrder: 'Tu orden',
        walletAddress: 'Tu dirección BTC',
      },
      finish: {
        your: 'Tus',
        viaYourAddress: 'Están en camino a tu dirección',
        thanks: 'Gracias por operar con nosotros',
        createNewOrder: 'Crear nueva órden',
        getOut: 'Salir',
      },
    },
    sell: {
      feeIncluded: 'fees Incluidos',
      wantSpend: 'Quiero gastar',
      orderSumary: {
        title: 'Resumen de tu orden de compra',
        cardTitle: 'Tu pedido de Latamex',
        checkData: 'Chequea todos los datos antes de continuar',
        totalSalesOrder: 'Total orden de venta:',
        estimatedCharges: 'Cargos estimados:',
        message1:
          'Comisión de cobro informada por el procesador de pagos local elegido en tu jurisdicción. El monto de la comisión será descontado del total a recibir.',
        totalPayment: 'Total a pagar',
        message2:
          'Su cobro será procesada por un procesador de pagos local. Antes de continuar debe aceptar los términos y condiciones y política de privacidad',
      },
      addBankAccount: {
        title: 'Agregar una cuenta bancaria',
        message1:
          'Por favor ingresa los datos de una cuenta bancaria de la cual seas titular, para recibir el pago de tu orden de venta.',
        message2: 'Importante: No podremos procesar la transacción si la cuenta no está a tu nombre:',
        cbuText: 'CBU',
        aliasText: 'Alias',
      },
      personalDataBankAccount: {
        title: 'Agregar una cuenta bancaria ',
        cuit: 'CUIT/CUIL',
        message1:
          'Por favor verifica los datos de tu cuenta, en caso de que no sean correctos tu transacción no podrá ser procesada. ',
      },
      selectBankAccount: {
        title: 'Selecciona una cuenta bancaria',
        addAccount: 'Agregar cuenta',
        message1: 'Por favor selecciona una cuenta donde recibirás la transacción. ',
      },
      dataTransfer: {
        orderNumber: 'Orden Nº',
        dataTransfer: '- Datos para transferencia ',
        message1pt1: 'Por favor realiza una transferencia de ',
        message1pt2: 'a la siguiente billetera. ',
        message1:
          'Importante: Si tu billetera cobra un cargo adicional por retiro, asegurate de agregarlo para que recibamos el importe completo. ',
        message2:
          'Una vez que recibamos la transferencia en nuestra billetera, enviaremos un link a tu correo para que confirmes tu orden con la cotización final de ese momento.',
      },
      orderConfirm: {
        title: 'Confirma tu orden Nº ',
        transferStatus: 'Estado de tu transferencia ',
        txid: 'Tx ID',
        receivedAmount: 'Cantidad Recibida',
        paymentAmount: 'Monto a cobrar ',
        paymentCommission: 'Comisión de cobro informada por el procesador de pagos ',
        totalReceive: 'Total a recibir ',
        message1:
          'Importante: Si no confirmas tu orden luego de 72hs de solicitada la confirmación, la misma se ejecutará de forma automática a valor final aplicable a ese momento. ',
      },
      finish: {
        orderComplete: '¡Tu orden fue completada! ',
        willReceivePayment: 'Pronto recibiras el pago en tu cuenta ',
        consultOrderStatus: 'Consulta el estado de tu orden ',
        backToHome: 'Volver al inicio',
        message1:
          'Las órdenes de venta confirmadas fuera de días y horarios bancarios serán procesados cuando los mismos se reanuden. ',
      },
    },
  },
  activity: {
    downloadPdf: 'Descargar PDF de la actividad',
    noMovements: 'Aun no tienes movimientos',
    purchased: 'Compraste',
    sold: 'Vendiste',
    firstBuy: 'Haz tu primera compra aquí',
    firstSell: 'Haz tu primera venta aquí',
  },
  authError: {
    [authErrors.invalidCredentials]: 'Credenciales no válidas',
    [authErrors.unavailableForCountry]: 'Settle Wallet no está disponible en tu país por el momento.',
    [authErrors.emailAlreadyInUse]: 'Email ya está en uso',
    [authErrors.networkError]: 'Error de conexion con el servidor',
    [authErrors.serverError]: 'Error interno.',
    [authErrors.unknown]: 'Ocurrió un error. Por favor inténtelo de nuevo.',
  },
  account: {
    requestIncrease: 'Solicitar aumento',
    identityVerification: 'Verificación de identidad',
    identityVerificationSuccess: 'Completado',
    identityVerificationIncomplete: 'Incompleto',
    identityVerificationPending: 'Pendiente',
    identityVerificationRejected: 'Rechazado',
    create: '¿No tienes Settle ID? Crear una cuenta',
    passwordError: 'Revisa tu contraseña',
    emailError: 'Revisa tu email',
    invalidEmailError: 'Email inválido',
    matchingPasswordError: 'Las contraseñas no coinciden',
    limit: {
      deposit: {
        daily: 'Límite de compra diario restante',
        monthly: 'Límite de compra mensual restante',
        biannual: 'Límite de compra semestral restante',
        anual: 'Límite de compra anual restante',
      },
      withdraw: {
        daily: 'Límite de venta diario restante',
        monthly: 'Límite de venta mensual restante',
        biannual: 'Límite de venta semestral restante',
        anual: 'Límite de venta anual restante',
      },
    },
    login: {
      button: 'Ingresar',
      emailPlaceholder: 'Email',
      passwordPlaceholder: 'Contraseña',
      recovery: 'Olvidé mi contraseña',
      subtitle: 'Ingresa con tu Settle ID',
      settleModal: '¿Qué es Settle ID?',
      title: 'Ingresar a Latamex con Settle ID',
    },
    recovery: {
      emailPlaceholder: 'Ingresa tu correo electrónico',
      subtitle: 'Ingresa tu correo electrónico, recibirás un código para restablecer tu contraseña.',
      title: 'Settle ID - Restablecer contraseña',
    },
    setPassword: {
      recovery: {
        subtitle:
          'Tu nueva contraseña debe tener al menos 8 caracteres, una letra, un número, y ser diferente a tu contraseña anterior.',
        title: 'Settle ID - Crea una nueva contraseña',
      },
      subtitle: 'Tu contraseña debe tener al menos 8 caracteres, una letra y un número.',
      title: 'Settle ID - Crea una contraseña',
      passwordPlaceholder: 'Contraseña',
      repeatPasswordPlaceholder: 'Repetir contraseña',
      repeatedPasswordError: 'La contraseña fue utilizada previamente',
    },
    signup: {
      login: '¿Ya tenes una cuenta Settle ID? Iniciar Sesión.',
      subtitle: 'Para crear tu cuenta, vamos a necesitar verificar tu correo.',
      settleWarning1: 'Atención',
      settleWarning2: ': Ahora Latamex requiere que crees una cuenta',
      settleWarning3: ', incluso si ya has operado con Latamex previamente desde un exchange',
      title: 'Crea tu Settle ID para operar en Latamex',
      emailPlaceholder: 'Ingresa tu correo electrónico',
      selectCountryPlaceholder: 'Seleccione país',
      settleModal: 'Settle ID',
    },
    verifyEmail: {
      recovery: {
        subtitle:
          ' hemos recibido tu solicitud. Recibirás un código en caso de que el email ingresado se corresponda con una cuenta Settle ID. (Revisa tu spam por las dudas)',
      },
      fail: '¡Código incorrecto!',
      resend: 'Reenviar código',
      resendCodeError: '¡Error en el envío del código!',
      resendCodeSuccess: '¡El código se ha enviado correctamente!',
      subtitle1: 'Hola ',
      subtitle2:
        ', para continuar por favor ingresa el código de 4 dígitos que te enviamos a tu correo. (Revisa tu spam por las dudas)',
      success: '¡Código correcto!',
      title: 'Settle ID - Verifica tu correo',
    },
  },
  modals: {
    settle: {
      confirm: 'Entendido',
      description1:
        'Es una cuenta única para acceder a diferentes aplicaciones, como Latamex y Stablex, sin tener que volver a completar todos tus datos. Pronto podrás usarlo para ingresar en otras aplicaciones asociadas.',
      description2:
        'Ahora el acceso a tu cuenta Latamex y Stablex se realiza a través de Settle ID. No necesitas hacer nada nuevo, si ya tenías una cuenta, tu email y contraseña de Latamex o Stablex, pasan a ser tu Settle ID. Si no tenías una cuenta deberás registrarte, solo tomará unos pocos minutos.',
      question: '¿Qué es Settle ID?',
    },
  },
  kyc: {
    overrideKycTitle: 'Verificación de identidad',
    idendityVerification: {
      title: 'Verificación de identidad',
      birthdate: 'Fecha de nacimiento',
      message1:
        'al ser la primera vez que utilizas Latamex, vamos a necesitar comprobar algunos de tus datos, solo va a tomar unos pocos minutos y será por única vez.',
    },
    basicData: {
      title: 'Verificación de identidad',
      message1: 'Por favor, ingresa tu(s) nombre(s), apellido y género.',
      names: 'Nombres',
      lastname: 'Apellido',
    },
    address: {
      title: 'Verificación de identidad',
      message1: 'Por favor ingresa la dirección del domicilio donde resides actualmente.',
      street: 'Calle',
      number: 'Número',
      floor: 'Piso',
      unit: 'Unidad',
      selectProvince: 'Seleccione una provincia',
      city: 'Ciudad',
      postalCode: 'Código postal',
    },
    profession: {
      title: 'Verificación de identidad',
      placeholderJob: 'Contanos a que te dedicas.',
      placeholderOcupation: 'Ocupación',
      pepCheckbox: 'No soy una P.E.P. (persona expuesta políticamente) ni sujeto obligado',
    },
    phoneVerify: {
      title: 'Ingresa tu número de telefono',
      message1: 'Para finalizar necesitamos verificar tu número de teléfono celular.',
      numberPhone: 'Número de teléfono',
    },
    codeVerify: {
      title: 'Ingresa tu número de telefono',
      message1: 'Por favor ingresa el código de 4 dígitos que te enviamos a tu teléfono celular.',
      resendCode: 'Reenviar código',
    },
    confirm: {
      message1:
        'Tu información fue enviada correctamente. Nuestro equipo está comenzando el proceso de revisión necesario ya que es la primera vez que operas con nosotros.',
      message2pt1: 'Mantenete atento a tu email',
      message2pt2:
        ', una vez que verifiquemos tu identidad, te enviaremos un link a tu correo para continuar con la transferencia.',
    },
    finish: {
      message1:
        'Tu identidad fue verificada correctamente. Podés continuar para obtener los datos de transferencia y finalizar tu órden.',
    },
  },
  widgets: {
    date: 'Fecha',
    order: 'Orden',
    transaction: 'Transacción',
    received: 'Recibido',
    status: 'Estado',
    amount: 'Importe',
    acceptThe: 'Aceoto los',
    termsAndConditions: 'términos y condiciones',
    paymentProcessor: 'del procesador de pagos.',
    copy: 'Copiar',
    actualPrice: 'Precio actual',
    updating: 'Actualizando en',
  },
  countries: {
    pick: 'Seleccione un pais',
    argentina: 'Argentina',
    brazil: 'Brasil',
    mexico: 'México',
  },
};
