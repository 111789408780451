import React from 'react';
import { Div, Image } from 'atomize';
import { withTranslation } from 'react-i18next';
import settleIcon from '../../assets/settle-id.svg';
import Text from '../widgets/Text';

const SettleId = ({ t }) => {
  return (
    <Div d="flex" flexDir="column" m="0 auto" overflow="hidden" align="center" p="1rem">
      <Text as="h1" textSize="f20" fontWeight="500">
        {t('modals.settle.question')}
      </Text>

      <Image src={settleIcon} h="6rem" m="1rem 0" />

      <Text lineHeight="20px" as="p" m="0 0 1rem" textAlign="center">
        {t('modals.settle.description1')}
      </Text>
      <Text lineHeight="20px" as="p" m="0 0 1rem" textAlign="center">
        {t('modals.settle.description2')}
      </Text>
    </Div>
  );
};

export default withTranslation()(SettleId);
