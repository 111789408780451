import React, { createContext } from 'react';
import { Api } from '../actions/Api';
import { cryptoCurrenciesKey } from '../constant/crypto';
import { formatMoney } from '../utils/money';

export const ActivityStateContext = createContext();
export const ActivityDispatchContext = createContext();

function activityReducer(state, action) {
  switch (action.type) {
    case 'LOADING': {
      return { ...state, loading: true, error: false };
    }
    case 'ACTIVITIES': {
      let activityList = action.data.data;

      if (activityList) {
        activityList = activityList.map((activity) => {
          let amountReceived = '';

          if (
            (activity.checkout.type === 'Deposit' || !activity.checkout.type) &&
            String(activity.fullyProcessed) === 'true'
          ) {
            amountReceived =
              Number.isNaN(Number(activity.checkout.checkoutAmount)) ||
              Number.isNaN(Number(activity.checkout.paidQuote))
                ? '-'
                : Number(activity.checkout.checkoutAmount) / Number(activity.checkout.paidQuote);
          }

          if (activity.checkout.type === 'Withdraw' && String(activity.fullyProcessed) === 'true') {
            amountReceived = Number.isNaN(Number(activity.checkout.paidAmount))
              ? activity.checkout?.checkoutAmount && activity.checkout?.quote
                ? activity.checkout.checkoutAmount * activity.checkout.quote
                : '-'
              : Number(activity.checkout.paidAmount);
          }

          const typeAsset = activity.checkout.destinationAsset || '';
          let customStatus = 'pending';

          switch (activity.status) {
            case 'Accepted':
              if (String(activity.fullyProcessed) === 'true') {
                customStatus = 'processed';
              }
              break;
            case 'Rejected':
              if (String(activity.fullyProcessed) === 'true') {
                customStatus = 'rejected';
              }
              break;
            case 'Expired':
              customStatus = 'expired';
              break;
            default:
              customStatus = 'pending';
          }

          return {
            ...activity,
            amount: `${activity.assetName} ${formatMoney(
              'en-US',
              activity.checkout.checkoutAmount + activity.checkout.feeAmount,
              cryptoCurrenciesKey[activity.checkout.originAsset]?.decimal ?? 0
            )}`,
            order: activity._id,
            received:
              activity.status === 'Accepted' && String(activity.fullyProcessed) === 'true'
                ? `${typeAsset} ${formatMoney(
                    'en-US',
                    amountReceived,
                    cryptoCurrenciesKey[activity.checkout.destinationAsset]?.decimal ?? 0
                  )}`
                : '-',
            date: new Date(activity.createdTimestamp).toLocaleDateString('en-GB'),
            customStatus,
          };
        });
      }

      return { ...state, activityList, total: action.data.total, loading: false, error: false };
    }
    case 'FAIL': {
      return { ...state, activityList: {}, total: 0, loading: false, error: action.data };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

const ActivityProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(activityReducer, {
    activityList: {},
    total: 0,
  });

  return (
    <ActivityStateContext.Provider value={state}>
      <ActivityDispatchContext.Provider value={dispatch}>{children}</ActivityDispatchContext.Provider>
    </ActivityStateContext.Provider>
  );
};

const getActivities = async (dispatch, { typeActivity, consumer, limit, offset, orderBy }) => {
  dispatch({ type: 'LOADING' });
  try {
    const { data, problem } = await Api.activities.getActivities(typeActivity, consumer, limit, offset, orderBy);
    if (problem) {
      dispatch({ type: 'FAIL', problem });
    } else {
      dispatch({ type: 'ACTIVITIES', typeActivity, data });
    }
  } catch (error) {
    dispatch({ type: 'FAIL', error });
  }
};

function useActivitiesState() {
  const context = React.useContext(ActivityStateContext);
  if (context === undefined) {
    throw new Error('useActivitiesState must be used within a ActivityProvider');
  }
  return context;
}

function useActivitiesDispatch() {
  const context = React.useContext(ActivityDispatchContext);
  if (context === undefined) {
    throw new Error('useActivitiesDispatch must be used within a ActivityProvider');
  }
  return context;
}

export { useActivitiesState, useActivitiesDispatch, ActivityProvider, getActivities };
