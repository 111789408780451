import React from 'react';
import { Image, Div } from 'react-atomize';
import { Anchor } from 'atomize';

// this is the 404 Error, page not found. is not finish obviusly :)
const NotFound = () => {
  return (
    <Div bg="white" h="100vh" d="flex" flexDir="column" justify="center" align="center">
      <Image w="60%" src="http://www.designingfever.com/wp-content/uploads/2014/05/Search-Again-designing-fever.gif" />
      <Anchor m={{ t: '1rem' }} href="/">
        Go home
      </Anchor>

      <Anchor m={{ t: '1rem' }} href="#!">
        Back
      </Anchor>
    </Div>
  );
};

export default NotFound;
