import React from 'react';
import { Div, Anchor, Image } from 'atomize';
import { useMediaQuery } from 'react-responsive';
import globeIcon from '../../assets/group-3.svg';
import DropdownLanguage from './DropdownLanguage';

const logo = '/assets/brand/logo-latamex-color.svg';
const AuthHeader = () => {
  const isMobile = useMediaQuery({ query: '(max-width:767px)' });
  const pathnameLocation = window.location.pathname;
  const pathnameLogin = pathnameLocation.includes('login');
  const pathnameRegister = pathnameLocation.includes('register');

  return (
    <Div d="flex" align="center" justify={(pathnameLogin || pathnameRegister) && isMobile ? 'space-between' : 'center'}>
      <Anchor href="/">
        <Image
          src={logo}
          h={(pathnameLogin || pathnameRegister) && isMobile ? '3rem' : '3rem'}
          w="auto"
          m=".5rem auto"
        />
      </Anchor>

      {(pathnameLogin || pathnameRegister) && isMobile && (
        <Div d="flex" justify="center" align="center">
          <Image src={globeIcon} w="20px" h="20px" m={{ r: '0.5rem' }} />
          <DropdownLanguage />
        </Div>
      )}
    </Div>
  );
};

export default AuthHeader;
