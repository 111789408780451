import { Api } from './Api';

const { api } = Api;

const { REACT_APP_CONSUMER } = process.env;

// Api calls get separed in case more tinkering with parameters is needed
// @Api calls
const callLogin = (email, password) => {
  return api.post('/settleid/auth/user/login', { email, password, consumer: REACT_APP_CONSUMER });
};

const callRegister = (email, countryCode) => {
  return api.post('/settleid/auth/user/register', { email, countryCode, consumer: REACT_APP_CONSUMER });
};

const callSendCode = (email, checkVerificationCodeMode) => {
  return api.patch('/settleid/auth/user/code/send', { email, checkVerificationCodeMode, consumer: REACT_APP_CONSUMER });
};

const callValidateCode = (email, verificationCodeHash, checkVerificationCodeMode) => {
  return api.post('/settleid/auth/user/code/validate', { email, verificationCodeHash, checkVerificationCodeMode });
};

const callSetPassword = (email, password, verificationCodeHash, changePasswordMode) => {
  return api.patch('/settleid/auth/user/password/set', {
    email,
    password,
    verificationCodeHash,
    changePasswordMode,
  });
};

const callRecoverPassword = (email) => {
  return api.patch('/settleid/auth/user/password/recover', { email, consumer: REACT_APP_CONSUMER });
};

const setVerificationMode = (data, checkVerificationCodeMode) => {
  data.checkVerificationCodeMode = checkVerificationCodeMode;
  return data;
};

const setPasswordChangeMode = (data, verificationCodeHash, checkVerificationCodeMode) => {
  data.verificationCodeHash = verificationCodeHash;
  data.changePasswordMode = checkVerificationCodeMode;
  return data;
};

// @Actions
async function login(dispatch, { email, password }) {
  dispatch({ type: 'START_UPDATE' });
  try {
    const { data, problem } = await callLogin(email, password);
    if (problem) {
      dispatch({ type: 'FAIL_UPDATE', data, problem });
    } else {
      dispatch({ type: 'USER_LOGIN', data });
    }
  } catch (error) {
    dispatch({ type: 'FAIL_UPDATE', error });
  }
}

async function register(dispatch, { email, countryCode }) {
  dispatch({ type: 'START_REGISTER' });
  try {
    const { data, problem } = await callRegister(email, countryCode);
    if (problem) {
      dispatch({ type: 'FAIL_REGISTER', problem });
    } else {
      data.data = setVerificationMode(data.data, 'sign-up');
      dispatch({ type: 'REGISTER', data });
    }
  } catch (error) {
    dispatch({ type: 'API_FAIL', error });
  }
}

async function sendCode(dispatch, { email, checkVerificationCodeMode }) {
  dispatch({ type: 'START_SEND_CODE' });
  try {
    const { data, problem } = await callSendCode(email, checkVerificationCodeMode);
    if (problem) {
      dispatch({ type: 'FAIL_SEND_CODE', problem });
    } else {
      data.data = setVerificationMode(data.data, checkVerificationCodeMode);
      dispatch({ type: 'SEND_CODE', data });
    }
  } catch (error) {
    dispatch({ type: 'API_FAIL', error });
  }
}

async function validateCode(dispatch, { email, verificationCodeHash, checkVerificationCodeMode }) {
  dispatch({ type: 'START_VALIDATE_CODE' });
  try {
    const { data, problem } = await callValidateCode(email, verificationCodeHash, checkVerificationCodeMode);
    if (problem) {
      dispatch({ type: 'FAIL_VALIDATE_CODE', problem });
    } else {
      data.data = setPasswordChangeMode(data.data, verificationCodeHash, checkVerificationCodeMode);
      dispatch({ type: 'VALIDATE_CODE', data });
    }
  } catch (error) {
    dispatch({ type: 'API_FAIL', error });
  }
}

async function setPassword(dispatch, { email, password, verificationCodeHash, changePasswordMode }) {
  dispatch({ type: 'START_SET_PASSWORD' });
  try {
    const { data, problem } = await callSetPassword(email, password, verificationCodeHash, changePasswordMode);
    if (problem) {
      dispatch({ type: 'FAIL_SET_PASSWORD', problem });
    } else {
      dispatch({ type: 'SET_PASSWORD', data });
    }
  } catch (error) {
    dispatch({ type: 'API_FAIL', error });
  }
}

async function recoverPassword(dispatch, { email }) {
  dispatch({ type: 'START_RECOVER_PASSWORD' });
  try {
    const { data, problem } = await callRecoverPassword(email);
    if (problem) {
      dispatch({ type: 'FAIL_RECOVER_PASSWORD', problem });
    } else {
      data.data = setVerificationMode(data.data, 'password-recovery');
      dispatch({ type: 'RECOVER_PASSWORD', data });
    }
  } catch (error) {
    dispatch({ type: 'API_FAIL', error });
  }
}

async function getUserInfo(dispatch) {
  dispatch({ type: 'USER_INFO_REQUEST' });
  try {
    const { data, problem } = await Api.getUserInfo();
    if (problem) {
      dispatch({ type: 'API_FAIL', problem });
    } else {
      dispatch({ type: 'USER_INFO_SUCCESS', data });
    }
  } catch (error) {
    dispatch({ type: 'FAIL_UPDATE', error });
    dispatch({ type: 'API_FAIL', error });
  }
}

export { login, register, sendCode, validateCode, setPassword, recoverPassword, getUserInfo };
