import React, { createContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { userReducer, initialState } from '../reducers/user';

export const UserStateContext = createContext();
export const UserDispatchContext = createContext();

const UserProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(userReducer, initialState);
  const { i18n } = useTranslation();
  const { userInfo } = state;
  useEffect(() => {
    switch (userInfo.user.countryCode) {
      case 'AR':
        i18n.changeLanguage('es');
        break;
      case 'MX':
        i18n.changeLanguage('mx');
        break;
      case 'BR':
        i18n.changeLanguage('pt');
        break;
      default:
        i18n.changeLanguage('es');
        break;
    }
  }, [userInfo.user.countryId]);

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>{children}</UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
};

function useUserState() {
  const context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error('useUserState must be used within a UserProvider');
  }
  return context;
}

function useUserDispatch() {
  const context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error('useUserDispatch must be used within a UserProvider');
  }
  return context;
}

export { useUserState, useUserDispatch, UserProvider };
