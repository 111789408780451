import React from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { Container, Div, Icon } from 'react-atomize';
import Popup from 'reactjs-popup';

import 'reactjs-popup/dist/index.css';

import ButtonPrimary from '../widgets/ButtonPrimary';

import KycWebappContainer from './KycWebappContainer';

import { KYCProvider } from '../../context/KycContext';

const Kyc = () => {
  const history = useHistory();
  const onFinish = (status) => {
    if (status && status !== 'Accepted') {
      return;
    }
    history.push('/');
  };
  const onCancel = () => {
    history.push('/');
  };

  const contentStyle = {
    width: '996px',
    maxWidth: '100%',
  };

  return (
    <Popup modal nested defaultOpen closeOnEscape={false} closeOnDocumentClick={false} {...{ contentStyle }}>
      {(close) => (
        <KYCProvider>
          <Container>
            <Div
              m={{ y: '2rem', x: 'auto' }}
              h={{ xs: 'auto', md: 'calc(100vh - 0rem)' }}
              d="flex"
              flexDir="column"
              justify="space-between"
              align="center"
              w="1440px"
              maxW="100%"
            >
              <Div
                w="100%"
                bg="white"
                shadow="1"
                rounded="xs"
                d="flex"
                m={{ t: '1rem' }}
                justify="space-between"
                flexDir={{ xs: 'column', md: 'row' }}
              >
                <ButtonPrimary
                  type="primary-square-disabled"
                  width={{ xs: '1.5rem', md: '1.5rem' }}
                  m="0.5rem"
                  p="0rem"
                  position="absolute"
                  right="2rem"
                  onClick={() => {
                    close();
                    history.push('/');
                  }}
                >
                  <Icon name="Cross" size="1.5rem" cursor="pointer" hoverColor="black50" />
                </ButtonPrimary>
                <KycWebappContainer onFinish={onFinish} onCancel={onCancel} />
              </Div>
            </Div>
          </Container>
        </KYCProvider>
      )}
    </Popup>
  );
};

export default withRouter(Kyc);
