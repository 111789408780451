import styled from 'styled-components';

export const Tabs = styled.div`
  /* width: 30%; */
`;

export const Tab = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
  min-width: 6rem;
  background-color: #fff;

  font-size: 14px;
  font-weight: 500;
  font-family: 'Mulish', sans-serif;
  color: ${(props) => (props.active ? props.theme.colors.primary100 : props.theme.colors.black80)};
  height: 3em;
  border-bottom: ${(props) => (props.active ? `1px solid ${props.theme.colors.primary100}` : '')};
`;
export const Content = styled.div`
  ${(props) => (props.active ? '' : 'display:none')}
`;
