import React, { useEffect, useState } from 'react';
import { Notification, Icon } from 'atomize';

const CustomNotification = ({ children, show, error }) => {
  const [showNotification, setShowNotification] = useState(show);

  useEffect(() => {
    setShowNotification(false);
    if (error) {
      setShowNotification(true);
    }
  }, [error]);

  return (
    <>
      <Notification
        bg="danger100"
        textColor="danger800"
        isOpen={showNotification}
        onClose={() => setShowNotification(false)}
        prefix={<Icon name="Close" color="danger800" m={{ r: '0.5rem' }} />}
        pos="static"
        bottom="0"
        m={{ l: '35%' }}
        align="center"
      >
        {children}
      </Notification>
    </>
  );
};

export default CustomNotification;
