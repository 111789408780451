import React from 'react';
import { withTranslation } from 'react-i18next';
import { Div } from 'react-atomize';
import ButtonPrimary from '../widgets/ButtonPrimary';

// this component contains the buttons buy and sell
const BuySellButtons = ({ setTypeTransaction, t }) => {
  // here set the status color to the buttons
  const { pathname } = window.location;
  let buyStyles;
  let sellStyles;
  if (pathname.includes('buy')) {
    buyStyles = 'secondary-circle-enabled';
    sellStyles = 'secondary-circle-disabled';
  } else if (pathname.includes('sell')) {
    buyStyles = 'secondary-circle-disabled';
    sellStyles = 'secondary-circle-enabled';
  }

  return (
    <>
      {/* alignment of buttons */}
      <Div d="flex" justify="space-around" w={{ xs: '100%', lg: '50%' }} m="0 auto">
        {/* button buy */}
        <ButtonPrimary
          onClick={() => setTypeTransaction('/buy/select-payment')}
          href="/transaccions/buy"
          type={buyStyles}
        >
          {t('global.buy')}
        </ButtonPrimary>

        {/* this separe the buttons with a 10% of with, makes the buttons not stick together */}
        <Div w={{ xs: '10%', lg: '0' }} />

        {/* button sell */}
        <ButtonPrimary
          onClick={() => setTypeTransaction('/sell/first-step')}
          href="/transaccions/sell"
          type={sellStyles}
        >
          {t('global.sell')}
        </ButtonPrimary>
      </Div>
    </>
  );
};

export default withTranslation()(BuySellButtons);
