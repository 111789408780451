import React from 'react';
import { Div } from 'react-atomize';
import CardsContainer from './CardsContainer';
import Main from '../widgets/Main';

// this componente render all the components in account layout
const Account = () => (
  <Main>
    <Div d="flex" flexDir="column" justify="space-between" align="center" w="100%">
      {/* inside of CardsContainer goes the logic to render */}
      <CardsContainer />
    </Div>
  </Main>
);

export default Account;
