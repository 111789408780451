import React from 'react';
import { Div, Image } from 'react-atomize';
import { withTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import Skeleton from 'react-loading-skeleton';
import CurrencyFormat from 'react-currency-format';
import { useHistory } from 'react-router-dom';

import Link from '../widgets/Link';
import Text from '../widgets/Text';

const getIcon = (name) => {
  let icon;
  try {
    // eslint-disable-next-line
    icon = require(`../../images/crypto/${name.toLowerCase()}.svg`);
  } catch (error) {
    // eslint-disable-next-line
    icon = require(`../../images/crypto/default.svg`);
  }

  return icon.default;
};

// ths component has the styles for the crypto widget
const BuySellCard = ({ cryptoInfo, loading, t, toAsset, item }) => {
  // destructuring the crypto values
  const { crypto, buy, sell } = cryptoInfo;

  const history = useHistory();

  return (
    <>
      <motion.div variants={item}>
        <Div
          w="100%"
          bg={loading ? 'transparent' : 'white'}
          rounded="md"
          shadow="2"
          p={loading ? '0' : '.75rem 1.25rem'}
        >
          {loading && <Skeleton height={103} />}
          {!loading && (
            <>
              <Div d="flex" align="center" justify="space-between" border={{ b: '1px solid' }} borderColor="black10">
                <Div d="flex" align="center">
                  {/* icon */}
                  <Image h="2rem" w="auto" m={{ r: '.5rem' }} src={getIcon(crypto)} />
                  {/* abbr */}
                  <Text fontWeight="500" m="1rem 0" fontFamily="secondary">
                    {crypto}
                  </Text>
                </Div>

                {/* this is the right side */}
                <Text fontWeight="500" m="1rem 0" fontFamily="secondary">
                  {/* currency | symbol | price */}
                  {toAsset}{' '}
                  <CurrencyFormat
                    value={buy ? buy.quote : 0}
                    displayType="text"
                    thousandSeparator=","
                    decimalSeparator="."
                    decimalScale={2}
                  />
                </Text>
              </Div>

              <Div d="flex" justify="space-around" p={{ t: '.5rem' }}>
                {/* here is the buttons */}
                {buy && <Link onClick={() => history.push(`/transactions/new/buy/${crypto}`)}>{t('global.buy')}</Link>}
                {sell && (
                  <Link onClick={() => history.push(`/transactions/new/sell/${crypto}`)}>{t('global.sell')}</Link>
                )}
              </Div>
            </>
          )}
        </Div>
      </motion.div>
    </>
  );
};

export default withTranslation()(BuySellCard);
