import React from 'react';
import { Div, Image } from 'react-atomize';
import { withTranslation } from 'react-i18next';
import Text from '../widgets/Text';

// notification container styles who receive notification as props
const IdentityStatus = ({ dataIdentityStatus, t }) => {
  const { name, birth, nationalId, country, gender, identity } = dataIdentityStatus;
  const formattedBirth = birth && birth.split('-').reverse().join('/');
  let identityText = '';
  let icon;

  switch (identity) {
    case 'Incomplete':
      identityText = t('account.identityVerificationPending');
      icon = 'litle-verifyPending';
      break;
    case 'New':
      identityText = t('account.identityVerificationPending');
      icon = 'litle-verifyPending';
      break;
    case 'Retry':
      identityText = t('account.identityVerificationPending');
      icon = 'litle-verifyPending';
      break;
    case 'Accepted':
      identityText = t('account.identityVerificationSuccess');
      icon = 'litle-verifySuccess';
      break;
    case 'Rejected':
      identityText = t('account.identityVerificationRejected');
      icon = 'litle-verifyRejected';
      break;
    default:
      icon = 'litle-verifyPending';
  }

  return (
    <Div
      bg="white"
      shadow="1"
      d="flex"
      m={{ b: '1rem' }}
      rounded="md"
      justify="space-between"
      align={{ xs: 'center' }}
      flexDir={{ xs: 'column', md: 'row' }}
      // p={{ l: '2rem', r: '2rem', y: '1rem' }}
      p="0 1.5rem"
    >
      {/* icon styles. all icons to render must be in the folder ---> "assets/icons/" <--- as src of the next component */}
      {/* <Image m={{ r: '0.5rem', y: '1rem', l: '1rem' }} h="24px" w="auto" src={`assets/icons/${icon}.svg`} /> */}
      <Image
        m={{ xs: '1.5rem auto 0', md: '1.5rem 1rem 1.5rem 0' }}
        h="1.5rem"
        w="auto"
        style={{ alignSelf: 'flex-start' }}
        src={`assets/icons/${icon}.svg`}
      />

      {/* container alignment title, message, and cta */}
      <Div
        d="flex"
        justify="space-between"
        align={{ xs: 'center' }}
        flexDir={{ xs: 'column', md: 'row' }}
        w="100%"
        p={{ y: '1rem' }}
      >
        {/* container alignment title, message */}
        <Div d="flex" justify="space-between" flexDir={{ xs: 'column', sm: 'row' }} style={{ columnGap: '2rem' }}>
          <Div>
            <Text fontFamily="secondary">{t('account.identityVerification')}</Text>
            <Text fontFamily="secondary">
              {country} - {identityText.toLowerCase()}
            </Text>
          </Div>

          <Div>
            <Text fontFamily="secondary">{name}</Text>
            <Text fontFamily="secondary">{formattedBirth}</Text>
          </Div>

          <Div>
            <Text fontFamily="secondary">{gender ? (gender === 'M' ? t('global.man') : t('global.woman')) : ''}</Text>
            <Text fontFamily="secondary">{nationalId && `${t('global.dni')}: ${nationalId || ''}`}</Text>
          </Div>
        </Div>

        {/* Status */}
        <Text cursor="default" textColor={`${identity === 'Incomplete' ? 'error' : 'success'}`}>
          {t(`account.${identity === 'Incomplete' ? 'identityVerificationIncomplete' : 'identityVerificationSuccess'}`)}
        </Text>
      </Div>
    </Div>
  );
};

export default withTranslation()(IdentityStatus);
