import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Container, Div } from 'react-atomize';

import FlexColumnCenter from '../widgets/FlexColumnCenter';
import ButtonPrimary from '../widgets/ButtonPrimary';
import BuySellButtons from './BuySellButtons';
import Messages from './Messages';

// imporing the buy and sell containers components
import BuyFormContainer from './buy/BuyFormContainer';
import SellFormContainer from './sell/SellFormContainer';
import NotFound from '../404NotFound/NotFound';

const Transaccions = ({ match, t }) => {
  const [typeTransaction, setTypeTransaction] = useState('/buy/select-payment');

  // this variable will get the component to render
  let component;

  // set the component to render to the variable while the params match with their case
  switch (match.params.type) {
    case 'buy':
      component = <BuyFormContainer />;
      break;
    case 'sell':
      component = <SellFormContainer />;
      break;
    default:
      component = <NotFound />;
  }

  return (
    <>
      <Container bg={{ xs: 'white', md: 'transparent' }}>
        {/* styles by default */}
        <Div
          bg="white"
          m={{ y: { xs: '0', md: '2rem' } }}
          p={{ xs: '2rem 0', md: '2rem' }}
          h={{ xs: 'auto', md: 'calc(100vh - 4rem)' }}
          d="flex"
          flexDir="column"
          justify="space-between"
          align="center"
        >
          {/* all this goes in the middle */}
          <FlexColumnCenter>
            {/* here is the buttons to buy or sell */}
            <BuySellButtons setTypeTransaction={setTypeTransaction} />

            {/* component/layout to render */}
            {component}

            {/* here is the message component, it has messages inside */}
            <Messages />
          </FlexColumnCenter>

          {/* this button goes in bottom */}
          <ButtonPrimary href={`/transaccions${typeTransaction}`}>{t('transactions.next')}</ButtonPrimary>
        </Div>
      </Container>
    </>
  );
};

export default withTranslation()(Transaccions);
