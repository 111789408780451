import { apiSauceProblemCodes, authErrors } from './constants';

const errorMap = {
  [apiSauceProblemCodes.CLIENT_ERROR]: {
    401: authErrors.invalidCredentials,
    403: authErrors.unavailableForCountry,
    409: authErrors.emailAlreadyInUse,
  },
  [apiSauceProblemCodes.SERVER_ERROR]: authErrors.serverError,
  [apiSauceProblemCodes.TIMEOUT_ERROR]: authErrors.networkError,
  [apiSauceProblemCodes.CONNECTION_ERROR]: authErrors.networkError,
  [apiSauceProblemCodes.NETWORK_ERROR]: authErrors.networkError,
  [apiSauceProblemCodes.CANCEL_ERROR]: authErrors.networkError,
};

export const getAuthError = (problem, statusCode) => {
  const error = statusCode ? errorMap[problem]?.[statusCode] : errorMap[problem];
  return error ?? authErrors.unknown;
};
