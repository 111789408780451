import React from 'react';
import { Api } from '../actions/Api';

const AllQuotesStateContext = React.createContext();
const AllQuotesDispatchContext = React.createContext();

function allQuotesReducer(state, action) {
  switch (action.type) {
    case 'START_UPDATE': {
      return { ...state, allQuotesInfo: {}, loading: true, error: false };
    }
    case 'QUOTE_UPDATE': {
      return { ...state, allQuotesInfo: action.data, loading: false, error: false };
    }
    case 'FAIL_UPDATE': {
      return { ...state, allQuotesInfo: {}, loading: false, error: action.data };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function AllQuotesProvider({ children }) {
  const [state, dispatch] = React.useReducer(allQuotesReducer, {
    allQuotesInfo: [],
  });
  return (
    <AllQuotesStateContext.Provider value={state}>
      <AllQuotesDispatchContext.Provider value={dispatch}>{children}</AllQuotesDispatchContext.Provider>
    </AllQuotesStateContext.Provider>
  );
}

async function updateAllQuotes(dispatch, { toAsset, pid }) {
  dispatch({ type: 'START_UPDATE' });
  try {
    const { data, problem } = await Api.getAllQuotes(toAsset, pid);
    if (problem) {
      dispatch({ type: 'FAIL_UPDATE', problem });
    } else {
      dispatch({ type: 'QUOTE_UPDATE', data });
    }
  } catch (error) {
    dispatch({ type: 'FAIL_UPDATE', error });
  }
}

function useAllQuotesState() {
  const context = React.useContext(AllQuotesStateContext);
  if (context === undefined) {
    throw new Error('useAllQuotesState must be used within a AllQuotesProvider');
  }
  return context;
}

function useAllQuotesDispatch() {
  const context = React.useContext(AllQuotesDispatchContext);
  if (context === undefined) {
    throw new Error('useAllQuotesDispatch must be used within a AllQuotesProvider');
  }
  return context;
}
export { AllQuotesProvider, useAllQuotesState, useAllQuotesDispatch, updateAllQuotes };
