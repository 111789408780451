export const cryptoCurrenciesKey = {
  BTC: { crypto: 'BTC', hasMemoTag: false, decimal: 8, decimalMarket: 6 },
  BNB: { crypto: 'BNB', hasMemoTag: true, decimal: 8, decimalMarket: 2 },
  ETH: { crypto: 'ETH', hasMemoTag: false, decimal: 8, decimalMarket: 5 },
  LTC: { crypto: 'LTC', hasMemoTag: false, decimal: 8, decimalMarket: 5 },
  NEO: { crypto: 'NEO', hasMemoTag: false, decimal: 0, decimalMarket: 0 },
  ONT: { crypto: 'ONT', hasMemoTag: false, decimal: 0, decimalMarket: 0 },
  TRX: { crypto: 'TRX', hasMemoTag: false, decimal: 6, decimalMarket: 1 },
  XLM: { crypto: 'XLM', hasMemoTag: true, decimal: 2, decimalMarket: 1 },
  XRP: { crypto: 'XRP', hasMemoTag: true, decimal: 2, decimalMarket: 1 },
  EOS: { crypto: 'EOS', hasMemoTag: true, decimal: 2, decimalMarket: 2 },
  TUSD: { crypto: 'TUSD', hasMemoTag: false, decimal: 2, decimalMarket: 2 },
  USDC: { crypto: 'USDC', hasMemoTag: false, decimal: 2, decimalMarket: 2 },
  USDT: { crypto: 'USDT', hasMemoTag: false, decimal: 2, decimalMarket: 2 },
  ARST: { crypto: 'ARST', hasMemoTag: 'optional', decimal: 2 },
  BRLT: { crypto: 'BRLT', hasMemoTag: 'optional', decimal: 2 },
  DAI: { crypto: 'DAI', hasMemoTag: false, decimal: 2, decimalMarket: 2, tradeMethod: 'sell' },
};
