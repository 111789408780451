import React from 'react';
import { withTranslation } from 'react-i18next';
import { Div } from 'react-atomize';

import ActivityDetail from './ActivityDetail';

// receive the activity as props and return widget with list of activity to render
const ActivityList = ({ activityData, typeActivity, t }) => {
  // title for "table"
  const filter = [
    {
      date: t('widgets.date'),
      order: t('widgets.order'),
      transaction: t('widgets.transaction'),
      amount: t('widgets.amount'),
      received: t('widgets.received'),
      status: t('widgets.status'),
    },
  ];

  return (
    <Div w="100%" m="1rem 0" className="overflow-x" p={{ xs: '1rem', md: '0' }}>
      {/* return the title for the table */}
      {filter.map((activity, index) => {
        const keyValue = index + 1;
        return <ActivityDetail title data={activity} key={keyValue} />;
      })}
      {/* setting the height to show the activity and scroll on the y axis */}
      <Div w="100%" maxH={{ xs: 'calc(100vh - 25rem)', lg: 'calc(100vh - 23.5rem)' }} className="overflow-y">
        {/* returns activity in list format  */}
        {activityData.map((activity, index) => {
          activity.status = t(`global.${activity.customStatus}`);
          return <ActivityDetail data={activity} loading typeActivity={typeActivity} key={index} />;
        })}
      </Div>
    </Div>
  );
};

export default withTranslation()(ActivityList);
