import React, { useEffect, useState } from 'react';
import { Image, Anchor, Col, Div } from 'atomize';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import loginIcon from '../../assets/account-login.svg';
import globeIcon from '../../assets/group-3.svg';
import AlignedModal from '../commons/Modal';
import { useAuthState, useAuthDispatch } from '../../context/AuthContext';
import ButtonPrimary from '../widgets/ButtonPrimary';
import { login } from '../../actions/auth';
import DropdownLanguage from './DropdownLanguage';
import CustomInput from '../commons/CustomInput';
import CustomNotification from '../commons/CustomNotification';
import Text from '../widgets/Text';

const isAValidEmail = /^[A-Z0-9._+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

const LogInContainer = ({ t }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [canSubmit, setCanSubmit] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const history = useHistory();
  const authDispatch = useAuthDispatch();
  const { error, loading, authInfo } = useAuthState();

  useEffect(() => {
    if (!error && submitted && !loading) {
      history.push('/');
      history.go(0);
    } else if (error && !loading) {
      setSubmitted(false);
    }
  }, [error, loading, submitted]);

  useEffect(() => {
    const popCallback = (event) => {
      if (
        event.currentTarget &&
        event.currentTarget.location &&
        event.currentTarget.location.pathname === '/auth/set-password'
      ) {
        history.push('/');
      }
    };
    window.addEventListener('popstate', popCallback);

    return () => {
      window.removeEventListener('popstate', popCallback);
    };
  }, []);

  const handleLogin = async () => {
    if (canSubmit) {
      setSubmitted(true);
      await login(authDispatch, { email, password });
    }
  };

  useEffect(() => {
    if (authInfo && authInfo.email) {
      setEmail(authInfo.email);
    }
  }, []);

  useEffect(() => {
    if (email && !isAValidEmail.test(email)) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }

    if (email && password && !emailError) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    }
  }, [email, password, emailError]);

  return (
    <Col minH={{ xs: 'auto', md: 'calc(100vh - 12rem)' }}>
      <Div pos="relative">
        <Text as="h1" textSize="f20" textAlign="center">
          {t('account.login.title')}
        </Text>

        <Div
          d={{ xs: 'none', md: 'flex' }}
          justify="center"
          align="center"
          pos={{ xs: 'relative', md: 'absolute' }}
          top="-.5rem"
          right={{ md: '2rem' }}
          m={{ xs: 'auto', b: '1rem' }}
        >
          <Image src={globeIcon} w="20px" h="20px" m={{ r: '0.5rem' }} />
          <DropdownLanguage />
        </Div>
      </Div>

      <Div w="100%" d="flex" flexDir="column" align="center">
        <Image src={loginIcon} w="5rem" m=".75rem 0 .5rem" />
        <AlignedModal title={t('account.login.settleModal')} />
        <CustomInput
          m={{ t: '.5rem' }}
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          label={t('account.login.emailPlaceholder')}
          placeholder={t('account.login.emailPlaceholder')}
          hasError={emailError}
          errorMessage={t('account.invalidEmailError')}
        />
        <CustomInput
          m="-1rem 0 0"
          name="password"
          value={password}
          type="password"
          onChange={(e) => setPassword(e.target.value)}
          label={t('account.login.passwordPlaceholder')}
          placeholder={t('account.login.passwordPlaceholder')}
        />
        <Anchor href="/auth/recover" d="block">
          {t('account.login.recovery')}
        </Anchor>
        <Anchor href="/auth/register" d="block" m={{ t: '0.5rem' }}>
          {t('account.create')}
        </Anchor>
        <ButtonPrimary
          disable={canSubmit.toString()}
          type={canSubmit ? 'primary-square-enabled' : 'primary-square-disabled'}
          onClick={handleLogin}
          width={{ xs: '15rem', md: '18rem' }}
          m="1.5rem 0 0 0"
        >
          {t('account.login.button')}
        </ButtonPrimary>
      </Div>
      <CustomNotification error={error}>{t(`authError.${error}`)}</CustomNotification>
    </Col>
  );
};

export default withTranslation()(LogInContainer);
