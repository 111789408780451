import React from 'react';
import styled from 'styled-components';

const TextUI = styled.p`
  font-size: ${(props) => props.theme.textSize[props.textSize] || props.theme.textSize.f14};
  color: ${(props) => props.theme.colors[props.textColor] || props.theme.colors.black80};
  font-weight: ${(props) => props.textWeight || 300};
  margin: ${(props) => props.m || 0};
  padding: ${(props) => props.p || 0};
  display: ${(props) => props.d || 'block'};
  font-family: ${(props) => props.theme.fontFamily[props.fontFamily] || props.theme.fontFamily.primary};
  font-weight: ${(props) => props.fontWeight || 400};
  text-align: ${(props) => props.textAlign || 'left'};
  line-height: ${(props) => props.lineHeight};
`;

const Text = ({ children, ...props }) => <TextUI {...props}>{children}</TextUI>;

export default Text;
