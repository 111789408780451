import React from 'react';
import { Div, Text } from 'react-atomize';

// this component just render messages received for props
const Message = ({ msg }) => {
  return (
    <Div w={{ xs: '100%', lg: '50%' }} m={{ y: '.25rem' }}>
      <Text textAlign="center">
        {/* here is the message */}
        {msg}
      </Text>
    </Div>
  );
};

export default Message;
