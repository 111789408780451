import React from 'react';
import { Div } from 'react-atomize';

import ActivityContainer from './ActivityContainer';
import { ActivityProvider } from '../../context/ActivityContext';
import Main from '../widgets/Main';

const Activity = () => {
  return (
    // the styles for the container.
    <Main>
      <Div bg="white" p={{ xs: '2rem 0', md: '2rem' }} d="flex" flexDir="column" align="center">
        {/* component that listing the activities */}
        <ActivityProvider>
          <ActivityContainer tableSize={15} />
        </ActivityProvider>
      </Div>
    </Main>
  );
};

export default Activity;
