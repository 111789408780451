import React, { useState } from 'react';
import { Div } from 'react-atomize';
import { useTranslation } from 'react-i18next';
import { Anchor, Dropdown } from 'atomize';
import i18n, { setCurrentLangToLocalStorage } from '../../i18n';

const SelectCountry = ({ onChange }) => {
  const { t } = useTranslation();
  // state for open or close the dropdown
  const [showDropdown, setShowDropdown] = useState(false);

  // state for take the country has been selected
  const [currentCountry, setCurrentCountry] = useState('countries.pick');

  // info for countries dropdown
  const dropDownCountries = [
    { name: 'country', url: '#!', title: 'countries.argentina', id: 'es', value: 2, countryCode: 'AR' },
    { name: 'country', url: '#!', title: 'countries.brazil', id: 'pt', value: 1, countryCode: 'BR' },
    { name: 'country', url: '#!', title: 'countries.mexico', id: 'mx', value: 4, countryCode: 'MX' },
  ];

  // click event for show/hidden the menu dropdown
  const dropdownClick = () => {
    setShowDropdown(!showDropdown);
  };

  const setCurrentLang = (lang) => {
    i18n.changeLanguage(lang);
    setCurrentLangToLocalStorage(lang);
  };

  // layout menu dropdown, travel de info countries
  const menuList = (
    <Div>
      {dropDownCountries.map((country) => (
        <Anchor
          key={country.title}
          onClick={() => {
            setCurrentCountry(country.title);
            setShowDropdown(false);
            setCurrentLang(country.id);
            onChange({ countryId: country.value, countryCode: country.countryCode });
          }}
        >
          <Div d="block" p=".5rem 1rem">
            {t(country.title)}
          </Div>
        </Anchor>
      ))}
    </Div>
  );

  return (
    <Dropdown isOpen={showDropdown} direction="bottomleft" onClick={dropdownClick} menu={menuList} h="3rem">
      {t(currentCountry)}
    </Dropdown>
  );
};

export default SelectCountry;
