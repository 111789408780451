import React from 'react';
import styled from 'styled-components';

const Anchor = styled.a`
  color: ${(props) => props.theme.colors[props.textColor] || props.theme.colors.primary100};
  transition: 0.3s;
  margin: ${(props) => props.m || '0 .25rem'};
  font-weight: ${(props) => props.weight || 500};
  font-size: ${(props) => props.textSize || props.theme.textSize.f14};

  :hover {
    color: ${(props) => props.theme.colors[props.hoverTextColor] || props.theme.colors.primary80};
  }
`;

// default styles for link
const Link = ({ children, href, disabled, textColor, m, hoverTextColor, weight, onClick, ...props }) => {
  return (
    <>
      <Anchor
        onClick={onClick}
        weight={weight}
        hoverTextColor={hoverTextColor}
        textColor={textColor}
        m={m}
        href={!disabled && href}
        {...{ props }}
      >
        {children}
      </Anchor>
    </>
  );
};

export default Link;
