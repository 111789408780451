import React, { useState } from 'react';
import { Div, Modal } from 'atomize';
import { withTranslation } from 'react-i18next';
import SettleId from '../auth/SettleId';
import Link from '../widgets/Link';

const AlignedModal = ({ t, title }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Link onClick={() => setShowModal(true)}>{title}</Link>
      <Modal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        align="center"
        rounded="md"
        maxW={{ xs: '25rem', md: '35rem' }}
        maxH={{ xs: 'auto', md: 'auto' }}
      >
        <SettleId />
        <Div d="flex" justify="center">
          <Link onClick={() => setShowModal(false)}>{t('modals.settle.confirm')}</Link>
        </Div>
      </Modal>
    </>
  );
};

export default withTranslation()(AlignedModal);
