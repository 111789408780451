import React from 'react';
import { withTranslation } from 'react-i18next';
import { Div } from 'react-atomize';
import { Anchor } from 'atomize';

// button styles default for all application, receive href, children, type as props

const ButtonPrimary = ({
  href,
  children,
  type,
  width,
  height,
  t,
  onClick,
  m,
  p,
  position,
  top,
  bottom,
  left,
  right,
}) => {
  // if dont have children, by default will be siguiente
  if (children === undefined) children = t('global.next');

  // if dont have a type button, by default will be primary-square
  if (type === undefined) type = 'primary-square-enabled';

  // if dont pass width as props at button, here set a width by default
  if (width === undefined) width = { xs: '100%', lg: '33.3%' };

  // declaring variables that change depending the button type
  let bg;
  let hoverBg;
  let rounded;
  let textColor;
  let textAlign;
  let borderColor;
  let hoverBorderColor;
  let cursor;
  let hoverTextColor;

  // styles for the different types of buttons
  // if you want a new button style, generate a new case and set values there
  switch (type) {
    case 'primary-square-enabled':
      bg = 'primary100';
      hoverBg = 'primary80';
      rounded = 'md';
      borderColor = 'primary100';
      hoverBorderColor = 'primary80';
      textColor = 'white';
      textAlign = 'center';
      break;
    case 'primary-circle-enabled':
      bg = 'primary100';
      hoverBg = 'primary80';
      rounded = 'circle';
      borderColor = 'primary100';
      hoverBorderColor = 'primary80';
      textColor = 'white';
      textAlign = 'center';
      break;
    case 'secondary-square-enabled':
      bg = 'transparent';
      hoverBg = 'primary100';
      rounded = 'md';
      borderColor = 'primary100';
      hoverBorderColor = 'primary80';
      textColor = 'primary100';
      hoverTextColor = 'white';
      textAlign = 'center';
      break;
    case 'secondary-circle-enabled':
      bg = 'transparent';
      hoverBg = 'transparent';
      rounded = 'circle';
      borderColor = 'primary100';
      hoverBorderColor = 'primary80';
      textColor = 'primary100';
      textAlign = 'center';
      break;

    case 'primary-square-disabled':
      bg = 'black05';
      hoverBg = 'black05';
      rounded = 'md';
      borderColor = 'black05';
      hoverBorderColor = 'transparent';
      textColor = 'black60';
      textAlign = 'center';
      cursor = 'default';
      break;
    case 'primary-circle-disabled':
      bg = 'black20';
      hoverBg = 'black40';
      rounded = 'circle';
      borderColor = 'black20';
      hoverBorderColor = 'transparent';
      textColor = 'black05';
      textAlign = 'center';
      cursor = 'auto';
      break;
    case 'secondary-square-disabled':
      bg = 'transparent';
      hoverBg = 'transparent';
      rounded = 'md';
      borderColor = 'black60';
      hoverBorderColor = 'black40';
      textColor = 'black60';
      textAlign = 'center';
      cursor = 'auto';
      break;
    case 'secondary-circle-disabled':
      bg = 'transparent';
      hoverBg = 'transparent';
      rounded = 'circle';
      borderColor = 'black60';
      hoverBorderColor = 'black40';
      textColor = 'black60';
      textAlign = 'center';
      cursor = 'auto';
      break;
    default:
  }

  return (
    <>
      {/* setting the width of the button */}
      <Div w={width} h={height} m={m} pos={position || 'static'} top={top} bottom={bottom} left={left} right={right}>
        {/* href value */}
        <Anchor href={href} onClick={onClick}>
          {/* setting styles for the button */}
          {/* if you generate a new button type add to next styles */}
          <Div
            w="100%"
            m={{ y: '.25rem' }}
            borderColor={borderColor}
            hoverBorderColor={hoverBorderColor}
            h="100%"
            d="flex"
            align="center"
            justify="center"
            p={p || '.5rem 0'}
            bg={bg}
            hoverBg={hoverBg}
            rounded={rounded}
            textColor={textColor}
            hoverTextColor={hoverTextColor}
            textAlign={textAlign}
            border="1px solid"
            cursor={cursor}
            transition
          >
            {/* children value */}
            {children}
          </Div>
        </Anchor>
      </Div>
    </>
  );
};

export default withTranslation()(ButtonPrimary);
