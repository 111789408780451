import { authErrors } from '../error/authError/constants';

export default {
  global: {
    next: 'Seguinte',
    hello: 'Olá',
    change: 'Mudança',
    limits: 'Limites',
    buy: 'Comprar',
    sell: 'Vender',
    cancelOrder: 'Para cancelar um pedido',
    cancel: 'Cancelar',
    total: 'Total',
    fiat: 'ARS',
    thanks: 'Obrigado',
    dni: 'CPF',
    confirm: 'Confirme',
    accredited: 'Acreditado',
    outstanding: 'Pendente de pagamento',
    cancelled: 'Cancelado',
    or: 'Ou',
    cbuText: 'CBU',
    aliasText: 'Alias',
    name: 'Nome',
    bank: 'Banco',
    finish: 'Finalizar',
    wallet: 'Carteira',
    network: 'Rede',
    memo: 'Memo',
    received: 'Recibido',
    address: 'Direção',
    search: 'Pesquisa',
    woman: 'Mulher',
    man: 'Homem',
    processed: 'Processado',
    rejected: 'Rejeitado',
    expired: 'Expirado',
    pending: 'Pendente',
    page: 'Página',
    of: 'De',
  },
  navbar: {
    home: 'Inicio',
    transactions: 'Comprar / Vender',
    activity: 'Minha atividade',
    account: 'Minha conta',
    logOut: 'Fechar sessão',
  },
  home: {
    activity: 'Minha atividade',
    buy: 'Comprar',
    sell: 'Vender',
    viewAll: 'Ver tudo',
    viewLess: 'Ver menos',
    toComplete: 'Completar',
    confirm: 'Confirme',
    requestIncrease: 'Solicitar aumento',
    dailyLimit: 'Limite diário restante',
    annualLimit: 'Limite anual restante',
    limits: 'Limites',
    identityVerification: 'Verificação de Identidade',
    orderN: 'Pedido nº',
    accredited: 'Acreditado',
    needConfirmation: 'só falta a sua confirmação ',
  },
  transactions: {
    next: 'Segue',
    message1: 'O preço inclui as despesas do processador de pagamentos ',
    message2: 'Ao continuar, você aceita os termos e condições. ',
    buy: {
      feeIncluded: 'taxas incluídas ',
      wantSpend: 'Eu quero gastar ',
      selectPayment: {
        title: 'Selecione um método de pagamento ',
        totalPayment: 'Total a pagar',
        paymentOptionTransfer: 'Transferência bancária',
        paymentOptionCash: 'Dinheiro',
        processorCharge: 'Carga do processador',
        message1: 'Seu pedido será processado por um processador de pagamento local. ',
        message2: 'Antes de continuar, você deve aceitar os termos e condições e a política de privacidade.',
      },
      orderSumary: {
        title: 'Resumo do seu pedido de pagamento ',
        cardTitle: 'Seu pedido na Latamex ',
        checkData: 'Verifique todos os dados antes de continuar ',
        totalSalesOrder: 'Pedido total de vendas: :',
        estimatedCharges: 'Encargos estimados: :',
        totalPayment: 'Total a pagar',
        message1:
          'Comissão de cobrança informada pelo processador de pagamento local escolhido em sua jurisdição. O valor da comissão será deduzido do total a ser recebido. ',
        message2:
          'Seu pedido será processado por um processador de pagamento local. Antes de continuar você deve aceitar os termos e condições e política de privacidade ',
      },
      enterWallet: {
        title: 'Carteira de destino',
        insertWalletAddress: 'Digite o endereço da sua carteira de ',
        toReceive: 'donde quieres recibir tus criptomonedas.',
        placeholderWalletAddress: 'Endereço da carteira ',
        placeholderMemo: 'Memo',
        memoDescription:
          'Muitas carteiras exigem que você insira um Memorando / Etiqueta. Caso tenha certeza de que não requer, marque a opção "não requer Memo / Tag". Se você completar esta opção incorretamente, seus fundos podem ser perdidos. Se você tiver dúvidas, pode ler mais sobre Memo / Tag em nossa central de ajuda.',
        memoTagCheckbox: 'Eu não quero MEMO / TAG',
      },
      dataTransfer: {
        title: 'Dados para transferência',
        message:
          'Faça uma transferência bancária de uma conta em seu nome, para o valor exato e inclua o número de referência, se o seu banco permitir essa opção, para que possamos identificar melhor o seu pagamento. Se o seu banco não permitir que você inclua o número de referência, você pode ignorá-lo.',
        cbuText: 'CBU',
        cbuValue: '1500647100064760309123',
        aliasText: 'Alias',
        aliasValue: 'SETTLE.REBANKING.ARS',
        referenceText: 'Referência',
        bankName: 'Banco Industrial',
        headline: 'Titular: Settle AR SAS',
        cuit: 'CUIT: 30-71652016-8',
      },
      endProcess: {
        message1: 'Os detalhes do seu pedido foram recebidos corretamente. Enviaremos um detalhe para o seu e-mail.',
        message2:
          'No momento de verificarmos o credenciamento do seu pagamento, enviaremos um e-mail para confirmar o seu pedido com o preço em vigor.',
        message3:
          'Os pagamentos recebidos fora do horário bancário serão processados ​​quando for reiniciado (de segunda a sexta-feira, das 10h às 15h).',
        message4:
          'Seu pedido terá validade de 48 horas úteis para ser pago, transcorrido esse tempo sem receber o pagamento, o pedido será automaticamente cancelado.',
      },
      statusOrder: {
        title: 'Verifique o status do seu pedido ',
        textDoubt: 'Se você tiver dúvidas sobre o status do seu pedido ',
        linkText: 'entre em contato com a equipe de suporte ',
        message1:
          'Insira o número do pedido (verifique nos e-mails que enviamos) e seu ID para que você possa verificar se seu pedido está pendente, concluído ou rejeitado.',
        queryDefault: 'Depósito',
        placeholderOrderNumber: 'Número do pedido ',
        checkOrder: 'Verifique o pedido ',
        orderStatus: 'Status do pedido',
        queryTypeDeposit: 'Depósito',
        queryTypeextraction: 'Extração',
      },
      confirmOrder: {
        title: 'Confirme o número do seu pedido ',
        paymentStatus: 'Status do seu pagamento ',
        yourOrder: 'Seu pedido ',
        walletAddress: 'Seu endereço BTC',
      },
      finish: {
        your: 'Seu',
        viaYourAddress: 'Obrigado por negociar conosco ',
        thanks: 'Obrigado por negociar conosco ',
        createNewOrder: 'Criar novo pedido ',
        getOut: 'Saia',
      },
    },
    sell: {
      feeIncluded: 'taxas incluídas ',
      wantSpend: 'Eu quero gastar ',
      orderSumary: {
        title: 'Resumo do seu pedido de compra',
        cardTitle: 'Seu pedido na Latamex',
        checkData: 'Verifique todos os dados antes de continuar',
        totalSalesOrder: 'Pedido total de vendas:',
        estimatedCharges: 'Encargos estimados:',
        message1:
          'Comissão de cobrança informada pelo processador de pagamento local escolhido em sua jurisdição. O valor da comissão será deduzido do total a ser recebido.',
        totalPayment: 'Total a pagar',
        message2:
          'Seu pagamento será processado por um processador de pagamentos local. Antes de continuar você deve aceitar os termos e condições e política de privacidade',
      },
      addBankAccount: {
        title: 'Adicione uma conta bancária',
        message1:
          'Insira os dados de uma conta bancária da qual você é o proprietário para receber o pagamento do seu pedido de venda.',
        message2: 'Importante: não poderemos processar a transação se a conta não estiver em seu nome:',
        cbuText: 'CBU',
        aliasText: 'Alias',
      },
      personalDataBankAccount: {
        title: 'Adicione uma conta bancária ',
        cuit: 'CUIT/CUIL',
        message1:
          'Por favor, verifique os detalhes da sua conta, se eles não estiverem corretos, sua transação não pode ser processada. ',
      },
      selectBankAccount: {
        title: 'Selecione uma conta bancária',
        addAccount: 'Adicionar',
        message1: 'Selecione uma conta na qual receberá a transação. ',
      },
      dataTransfer: {
        orderNumber: 'No. do pedido ',
        dataTransfer: '- Dados para transferência',
        message1pt1: 'Faça uma transferência de ',
        message1pt2: 'para a próxima carteira. ',
        message1:
          'Importante: Se sua carteira cobra uma taxa de saque adicional, certifique-se de adicioná-la para que recebamos o valor total. ',
        message2:
          'Assim que recebermos a transferência na nossa carteira, enviaremos um link para o seu email para que possa confirmar a sua encomenda com o orçamento final nessa altura.',
      },
      orderConfirm: {
        title: 'Confirme o número do seu pedido ',
        transferStatus: 'Status de sua transferência ',
        txid: 'Tx ID',
        receivedAmount: 'Valor recebido ',
        paymentAmount: 'Quantia a recolher ',
        paymentCommission: 'Comissão de cobrança informada pelo processador de pagamento',
        totalReceive: 'Total a receber ',
        message1:
          'Importante: Caso não confirme seu pedido após 72 horas da solicitação da confirmação, ele será executado automaticamente no valor final aplicável naquele momento. ',
      },
      finish: {
        orderComplete: '¡Seu pedido foi concluído!',
        willReceivePayment: 'Em breve você receberá o pagamento em sua conta ',
        consultOrderStatus: 'Verifique o status do seu pedido',
        backToHome: 'Voltar ao início',
        message1:
          'Os pedidos de vendas confirmados fora dos dias e horários bancários serão processados ​​quando forem retomados. ',
      },
    },
  },
  activity: {
    downloadPdf: 'Baixe o PDF da atividade',
    noMovements: 'Você ainda não tem movimentos',
    purchased: 'Você comprou',
    sold: 'Você vendeu',
    firstBuy: 'Faça sua primeira compra aqui',
    firstSell: 'Faça sua primeira venda aqui',
  },
  authError: {
    [authErrors.invalidCredentials]: 'Credenciais inválidas',
    [authErrors.unavailableForCountry]: 'Settle Wallet não está disponível em seu país no momento.',
    [authErrors.emailAlreadyInUse]: 'O e-mail já está sendo usado',
    [authErrors.networkError]: 'Erros de conexão',
    [authErrors.serverError]: 'Erro interno',
    [authErrors.unknown]: 'Ocorreu um erro. Por favor, tente novamente.',
  },
  account: {
    requestIncrease: 'Solicitar aumento',
    identityVerification: 'Verificação de Identidade',
    identityVerificationSuccess: 'Concluído',
    identityVerificationIncomplete: 'Incompleto',
    identityVerificationPending: 'Pendente',
    identityVerificationRejected: 'Rejeitado',
    create: 'Não tem um Settle ID? Criar uma conta',
    passwordError: 'Verifique sua senha',
    emailError: 'Verifique seu e-mail',
    invalidEmailError: 'Email inválido',
    matchingPasswordError: 'As senhas não coincidem',
    limit: {
      deposit: {
        daily: 'Limite de compra diária restante',
        monthly: 'Limite de compra por mês restante',
        biannual: 'Limite de compra semestral restante',
        anual: 'Limite de compra anual restante',
      },
      withdraw: {
        daily: 'Limite de venda diária restante',
        monthly: 'Limite de venda por mês restante',
        biannual: 'Limite de venda semestral restante',
        anual: 'Limite de venda anual restante',
      },
    },
    login: {
      button: 'Confirme',
      emailPlaceholder: 'Insira seu e-mail',
      passwordPlaceholder: 'Coloque sua senha',
      recovery: 'Esqueci minha senha',
      subtitle: 'Insira com o seu Settle ID',
      settleModal: 'O que é Settle ID?',
      title: 'Entrar na Latamex',
    },
    recovery: {
      emailPlaceholder: 'Digite seu e-mail',
      subtitle: 'Digite seu e-mail, você receberá um código para redefinir sua senha',
      title: 'Settle ID - Redefina sua senha',
    },
    setPassword: {
      recovery: {
        subtitle:
          'Sua nova senha deve ter pelo menos 8 caracteres, uma letra, um número e ser diferente de sua senha antiga.',
        title: 'Settle ID - Crie uma nova senha',
      },
      subtitle: 'Sua senha deve ter pelo menos 8 caracteres, uma letra y um número.',
      title: 'Settle ID - Crie uma senha',
      passwordPlaceholder: 'Digite sua senha',
      repeatPasswordPlaceholder: 'Repetir a senha',
      repeatedPasswordError: 'A senha foi usada anteriormente',
    },
    signup: {
      login: 'Já tem uma conta Settle ID? Iniciar sessão',
      subtitle: 'Para criar seu conta, precisamos verificar seu e-mail.',
      title: 'Crie sua Settle ID para ingressar na Latamex',
      settleWarning1: 'Atenção',
      settleWarning2: ': agora Latamex requer que você crie uma conta',
      settleWarning3: ', mesmo que você tenha negociado anteriormente com a Latamex em uma bolsa',
      emailPlaceholder: 'Insira seu e-mail',
      settleModal: 'Settle ID',
    },
    verifyEmail: {
      recovery: {
        subtitle:
          ' recebemos o seu pedido. Você receberá um código caso o email digitado corresponda a uma conta Settle ID. (Verifique o seu spam apenas no caso de)',
      },
      fail: 'Código errado',
      resend: 'Reenviar código',
      resendCodeError: 'Erro no envio do código!',
      resendCodeSuccess: 'O código foi enviado com sucesso!',
      subtitle1: 'Olá ',
      subtitle2:
        ', para continuar por favor introduza o código de 4 dígitos que lhe enviámos para o seu e-mail. (Verifique o seu spam apenas no caso de) ',
      success: 'Código correto!',
      title: 'Settle ID - Verifique o seu e-mail',
    },
  },
  modals: {
    settle: {
      confirm: 'Entendido',
      description1:
        'É uma conta única para acessar diferentes plataformas, como Latamex e Stablex, sem ter que preencher todos os seus dados novamente. Em breve você poderá usá-lo para entrar em outras plataformas parceiras. ',
      description2:
        'Agora o acesso à sua conta Latamex e Stablex é feito através do Settle ID. Você não precisa fazer nada novo, se você já tiver uma conta, seu e-mail e senha Latamex ou Stablex se tornarão seu ID do Settle. Se você não tiver uma conta, precisará se registrar, isso levará apenas alguns minutos.',
      question: 'O que é Settle ID?',
    },
  },
  kyc: {
    overrideKycTitle: 'Verificação de identidade',
    idendityVerification: {
      title: 'Verificação de Identidade',
      birthdate: 'Data de nascimento',
      message1:
        'Por ser a primeira vez que você usa o Latamex, precisaremos verificar alguns de seus dados, isso levará apenas alguns minutos e será apenas uma vez.',
    },
    basicData: {
      title: 'Verificação de Identidade',
      message1: 'Insira seu (s) nome (s), sobrenome e gênero. .',
      names: 'Nomes ',
      lastname: 'Sobrenome',
    },
    address: {
      title: 'Verificação de Identidade',
      message1: 'Insira o endereço do endereço onde você reside atualmente.',
      street: 'Rua',
      number: 'Número',
      floor: 'Chão',
      unit: 'Unidade',
      selectProvince: 'Selecione uma província ',
      city: 'Cidade',
      postalCode: 'Código postal',
    },
    profession: {
      title: 'Verificação de Identidade',
      placeholderJob: 'Diga-nos o que você faz. .',
      placeholderOcupation: 'Ocupação',
      pepCheckbox: 'Não sou um P.E.P. (pessoa politicamente exposta) ou sujeito obrigado',
    },
    phoneVerify: {
      title: 'Digite o seu número de telefone ',
      message1: 'Por fim, precisamos verificar o número do seu celular.',
      numberPhone: 'Número de telefone',
    },
    codeVerify: {
      title: 'Digite o seu número de telefone',
      message1: 'Digite o código de 4 dígitos que enviamos para o seu celular.',
      resendCode: 'Reenviar código',
    },
    confirm: {
      message1:
        'Suas informações foram enviadas corretamente. Nossa equipe está iniciando o processo de revisão necessário, pois esta é a primeira vez que negocia conosco.',
      message2pt1: 'Fique atento ao seu e-mail',
      message2pt2:
        ', Assim que verificarmos sua identidade, enviaremos um link para seu e-mail para continuar com a transferência.',
    },
    finish: {
      message1:
        'Sua identidade foi verificada com sucesso. Você pode continuar a obter os dados de transferência e finalizar seu pedido.',
    },
  },
  widgets: {
    date: 'Data',
    order: 'Pedido',
    transaction: 'Transação',
    received: 'Recebido',
    status: 'Estado',
    amount: 'Valor',
    acceptThe: 'Eu aceito o',
    termsAndConditions: 'termos e condições',
    paymentProcessor: 'do processador de pagamento.',
    copy: 'cópia de',
    actualPrice: 'Preço real',
    updating: 'Atualizando em ',
  },
  countries: {
    pick: 'Selecione um pais',
    argentina: 'Argentina',
    brazil: 'Brasil',
    mexico: 'México',
  },
};
