export const moneyMapping = {
  AR: 'ARS',
  BR: 'BRL',
  MX: 'MXN',
};

export const langMapping = {
  AR: 'es',
  BR: 'pt',
  MX: 'mx',
};

export const countryMapping = {
  es: 'AR',
  pt: 'BR',
  mx: 'MX',
};
