import React, { useState, useEffect } from 'react';
import { Div } from 'react-atomize';
import { motion } from 'framer-motion';
import { withTranslation } from 'react-i18next';
import { useAllQuotesState, updateAllQuotes, useAllQuotesDispatch } from '../../context/QuoteContext';
import { container, item } from './framer/BuySellContainer';

import { BuySellCards } from './StyledComponents';
import BuySellCard from './BuySellCard';
import ButtonPrimary from '../widgets/ButtonPrimary';

const BuySellContainer = ({ toAsset, pid, t }) => {
  // this state allow show all criptos availables if this is true, if this is false by default show 6
  const [showAllCurrencies, setShowAllCurrencies] = useState(false);

  // this function change the state to rus o false
  const showAllCurrenciesClick = () => {
    setShowAllCurrencies(!showAllCurrencies);
  };

  const allQuotesDispatch = useAllQuotesDispatch();

  useEffect(() => {
    if (toAsset && pid) {
      updateAllQuotes(allQuotesDispatch, { toAsset, pid });
    }
  }, [allQuotesDispatch, toAsset, pid]);

  const { allQuotesInfo, loading, error } = useAllQuotesState();

  const Loader = () => {
    const items = [];

    for (let i = 0; i < 8; i += 1) {
      items.push(<BuySellCard key={i} cryptoInfo={{ name: 'loading' }} loading />);
    }

    return items;
  };

  const filteredCryptoList =
    allQuotesInfo.length > 0 ? allQuotesInfo.filter((crypto) => crypto.buy || crypto.sell) : {};

  if (error || error === undefined) {
    return (
      <Div d="flex" flexDir="column" align="center">
        <Div w="100%" d="flex" justify="space-between" flexWrap="wrap" m={{ b: '1rem' }}>
          <h3>Oops... algo no salió como esperabamos</h3>
        </Div>
      </Div>
    );
  }

  return (
    <>
      {/* alignment of the widgets and button */}
      <Div d="flex" flexDir="column" align="center">
        {/* alignment of the widgets */}
        <motion.div style={{ width: '100%' }} variants={container} initial="hidden" animate="visible">
          <BuySellCards m={{ b: '1rem' }}>
            {loading !== undefined && !loading && filteredCryptoList ? (
              filteredCryptoList.slice(0, showAllCurrencies ? filteredCryptoList.length : 8).map((crypto) => {
                return <BuySellCard item={item} key={crypto.crypto} cryptoInfo={crypto} toAsset={toAsset} />;
              })
            ) : (
              <Loader />
            )}
          </BuySellCards>
        </motion.div>

        {/* here is the button that change the state and show the currencies, the button is inside of a div, for give him an onClick and a width */}
        {filteredCryptoList && filteredCryptoList.length > 8 && (
          <Div onClick={showAllCurrenciesClick} w={{ xs: '100%', md: 'calc(33.3% - 1rem)' }}>
            <ButtonPrimary width="100%" type="secondary-square-enabled">
              {showAllCurrencies ? t('home.viewLess') : t('home.viewAll')}
            </ButtonPrimary>
          </Div>
        )}
      </Div>
    </>
  );
};

export default withTranslation()(BuySellContainer);
