import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Main from '../widgets/Main';
import BuySellContainer from './BuySellContainer';
import NotificationContainer from './NotificationContainer';
import Activity from './Activity';
import ErrorBoundary from '../errorBoundary/errorBoundary';

import { AllQuotesProvider } from '../../context/QuoteContext';
import { useUserState } from '../../context/UserContext';

import { moneyMapping } from '../../constant/countryMap';

// this component return all the layot on home page
const Home = () => {
  const history = useHistory();
  const {
    userInfo: { user },
  } = useUserState();

  const countryAsset = moneyMapping[user.countryCode];

  useEffect(() => {
    const popCallback = (event) => {
      if (
        event.currentTarget &&
        event.currentTarget.location &&
        event.currentTarget.location.pathname === '/auth/login'
      ) {
        history.push('/');
      }
    };
    window.addEventListener('popstate', popCallback);

    return () => {
      window.removeEventListener('popstate', popCallback);
    };
  }, []);

  return (
    <Main>
      {/* Here is the crypto widgets */}
      <ErrorBoundary>
        <AllQuotesProvider>
          <BuySellContainer toAsset={countryAsset} pid="qG6peWvso3" />
        </AllQuotesProvider>
      </ErrorBoundary>
      {/* Here is the notifications widgets */}
      <NotificationContainer />
      {/* Here is the activity widget */}
      <Activity />
    </Main>
  );
};

export default Home;
