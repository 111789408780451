import React from 'react';
import { Div } from 'react-atomize';

const FlexColumnCenter = ({ children, flexDir, justify, align, m }) => {
  if (flexDir === undefined) flexDir = 'column';
  if (justify === undefined) justify = 'center';
  if (align === undefined) align = 'center';

  return (
    // this component just align in vertical align center all that is be inside
    <Div m={m} w="100%" d="flex" flexDir={flexDir} justify={justify} align={align}>
      {children}
    </Div>
  );
};

export default FlexColumnCenter;
