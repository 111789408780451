import React from 'react';
import { Div } from 'react-atomize';

import ActivityContainer from '../activity/ActivityContainer';
import { ActivityProvider } from '../../context/ActivityContext';

// this component render the topbar made inside of this section
const Activity = () => (
  // styles for the activity container
  <Div
    bg="white"
    shadow="1"
    rounded="md"
    p="2rem"
    d="flex"
    m={{ t: '1rem' }}
    justify="space-between"
    align={{ xs: 'center', md: 'flex-start' }}
    flexDir="column"
  >
    <ActivityProvider>
      <ActivityContainer tableSize={5} hidePagination />
    </ActivityProvider>
  </Div>
);

export default Activity;
