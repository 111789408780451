import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { Div, Image } from 'react-atomize';
import Skeleton from 'react-loading-skeleton';
import Link from '../widgets/Link';
import Text from '../widgets/Text';

import { useActivitiesState, useActivitiesDispatch, getActivities } from '../../context/ActivityContext';
import ActivityList from '../widgets/activitiesList/ActivityList';
import Pagination from '../widgets/Pagination';
import { Tabs, Tab } from '../widgets/Tabs';
import logo from '../../images/order.svg';

const orderBy = '{ "createdTimestamp": -1 }';

// this component is for apply logic necesary if the user use the searchbar o filters
const ActivityContainer = ({ tableSize, hidePagination, t }) => {
  //  getting the activity data of the user logged with hook useContext
  const { activityList, total: activitiesTotal, loading } = useActivitiesState();
  const [typeActivity, setTypeActivity] = useState('deposits');
  const [currentPage, setCurrentPage] = useState({
    deposits: 1,
    withdraws: 1,
  });

  const numberOfPages = parseInt(activitiesTotal / tableSize, 10) + (activitiesTotal % tableSize ? 1 : 0);

  const activitiesDispatch = useActivitiesDispatch();

  useEffect(() => {
    getActivities(activitiesDispatch, {
      typeActivity,
      consumer: 'latamex',
      limit: tableSize,
      offset: typeActivity === 'deposits' ? currentPage.deposits - 1 : currentPage.withdraws - 1,
      orderBy,
    });
  }, [currentPage, typeActivity, activitiesDispatch, tableSize]);

  const [active, setActive] = useState(0);
  const handleClick = ({ target: { value, id } }) => {
    setTypeActivity(value);
    const index = parseInt(id, 2);
    if (index !== active) {
      setActive(index);
    }
  };

  return (
    <>
      <Div
        w={{ xs: '90%', md: '100%' }}
        d="flex"
        flexDir={{ xs: 'column', sm: 'row' }}
        align="center"
        justify="space-between"
      >
        <Text textColor="black90" textSize="f24">
          {t('home.activity')}
        </Text>
        <Div p={{ xs: '1rem 0', sm: '0' }}>
          <Tabs>
            <Tab onClick={handleClick} active={active === 0} id={0} value="deposits">
              {t('global.buy')}
            </Tab>

            <Tab onClick={handleClick} active={active === 1} id={1} value="withdraws">
              {t('global.sell')}
            </Tab>
          </Tabs>
        </Div>
      </Div>
      {loading && (
        <Div w="100%">
          <Skeleton height={24} count={tableSize} />
        </Div>
      )}
      {/* noMovements  */}
      {!loading && activitiesTotal === 0 && (
        <Div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Div textAlign="center" m="3rem 0">
            <Image src={logo} h="8rem" m="1rem auto 0" />
            <Text textColor="black40" fontFamily="secondary" textSize="f20" m="1rem 0">
              {t('activity.noMovements')}
            </Text>
            <Link href={`/transactions/new/${typeActivity === 'deposits' ? 'buy' : 'sell'}`} weight="500">
              {t(`activity.${typeActivity === 'deposits' ? 'firstBuy' : 'firstSell'}`)}
            </Link>
          </Div>
        </Div>
      )}
      {activitiesTotal > 0 && !loading && (
        <>
          <ActivityList typeActivity={typeActivity} activityData={activityList} />
        </>
      )}
      {activitiesTotal > 0 &&
        (hidePagination ? (
          activitiesTotal > 5 && (
            <Div w="100%" d="flex" flexDir={{ xs: 'column', sm: 'row' }} align="center" justify="flex-end">
              <Link href="/activity">{t('home.activity')}</Link>
            </Div>
          )
        ) : (
          <Div w="100%">
            <Pagination
              currentPage={typeActivity === 'deposits' ? currentPage.deposits : currentPage.withdraws}
              numberOfPages={numberOfPages}
              onChangeCurrentPage={(val) =>
                setCurrentPage({
                  ...currentPage,
                  deposits: typeActivity === 'deposits' ? val : currentPage.deposits,
                  withdraws: typeActivity === 'withdraws' ? val : currentPage.withdraws,
                })
              }
              totalOfItems={activitiesTotal}
              tableSize={tableSize}
            />
          </Div>
        ))}
    </>
  );
};

export default withTranslation()(ActivityContainer);
