import React from 'react';
import { Div } from 'react-atomize';
import { motion } from 'framer-motion';

const Main = ({ children, bg = 'transparent' }) => {
  // geting the current pathname
  const locationPathname = window.location.pathname;

  // if current page is home, don't have background color
  if (locationPathname === '/') {
    bg = 'transparent';
  }

  const container = {
    initial: {
      y: 100,
    },
    animate: {
      y: 0,
    },
  };

  const spring = {
    type: 'spring',
    damping: 10,
    stiffness: 100,
  };

  return (
    <motion.div variants={container} initial="initial" animate="animate" spring={spring}>
      <Div
        rounded="md"
        className="overflow-y"
        maxH={{ xs: 'auto', xl: '100vh' }}
        p={{ xs: '1rem', lg: '3rem' }}
        bg={bg}
      >
        {children}
      </Div>
    </motion.div>
  );
};

export default Main;
