import React, { useEffect, useState } from 'react';
import { Div } from 'react-atomize';
import Popup from 'reactjs-popup';
import { useHistory } from 'react-router-dom';
import { useUserState, useUserDispatch } from '../../../context/UserContext';
import { getKycInfo } from '../../../actions/user';
import { useCheckoutState, createCheckout, useCheckoutDispatch } from '../../../context/CheckoutContext';
import Main from '../../widgets/Main';

const BuySellProcess = ({ match }) => {
  const { userInfo } = useUserState();

  const [loadedEventListener, setLoadedEventListener] = useState(false);
  const [iframeUrl, setIframeUrl] = useState(null);
  const [openedPopup, setOpenedPopup] = useState(false);
  const checkoutDispatch = useCheckoutDispatch();
  const userDispatch = useUserDispatch();
  const { checkoutInfo } = useCheckoutState();
  const baseURL = `${process.env.REACT_APP_WIDGET_URL}&email=`;
  const history = useHistory();

  useEffect(() => {
    getKycInfo(userDispatch, () => {
      history.push('/kyc/identity-identification');
    });
  }, [userDispatch]);

  useEffect(() => {
    if (!userInfo || (userInfo && !userInfo.user.email)) return;
    if (!iframeUrl) {
      console.log(userInfo);
      const { crypto = '', type = 'buy' } = match.params;
      setIframeUrl(
        `${baseURL}${userInfo.user.email}&controlled=true&type=${type}&crypto=${crypto}&country=${userInfo.user.countryCode}`
      );
    }

    if (!loadedEventListener && iframeUrl) {
      window.addEventListener('message', handleIframeResponse);
      setLoadedEventListener(true);
    }
  }, [loadedEventListener, userInfo, iframeUrl]);

  useEffect(() => {
    if (checkoutInfo.redirectUrl) {
      const authToken = localStorage.getItem('userToken');
      // setIframeUrl(`${checkoutInfo.redirectUrl}&authtoken=${authToken}&embedded=1`);
      const newCheckoutTab = window.open(`${checkoutInfo.redirectUrl}&authtoken=${authToken}&embedded=1`, '_blank');
      setOpenedPopup(true);
      newCheckoutTab.focus();
      const timer = setInterval(function () {
        if (newCheckoutTab.closed) {
          clearInterval(timer);
          setOpenedPopup(false);
          history.push('/');
        }
      }, 1000);
    }
  }, [checkoutInfo]);

  const sourceString = (url) =>
    url.replace('http://', '').replace('https://', '').replace('www.', '').split(/[/?#]/)[0];

  const handleIframeResponse = (e) => {
    if (iframeUrl && sourceString(e.origin) === sourceString(iframeUrl)) {
      const {
        data: { event },
      } = e;
      switch (event) {
        case 'newCheckout':
          handleNewCheckout(e.data.checkoutData);
          break;
        default:
          console.log(e.data);
      }
    }
  };

  const handleNewCheckout = (checkoutData) => {
    createCheckout(checkoutDispatch, { ...checkoutData, callbackUrl: '/#/application/close' });
  };

  return (
    <>
      <Popup modal nested open={openedPopup} closeOnEscape={false} closeOnDocumentClick={false}>
        <div className="spinner">
          <div className="bounce1" />
          <div className="bounce2" />
          <div className="bounce3" />
        </div>
      </Popup>
      <Main>
        <Div
          w={{ xs: '100%', md: '90%' }}
          h="85vh"
          bg="white"
          shadow="1"
          rounded="xs"
          p={{ r: '1rem' }}
          d="flex"
          m="auto"
          justify="space-between"
          align={{ xs: 'center' }}
          flexDir={{ xs: 'column', md: 'row' }}
        >
          {userInfo && userInfo.user && (
            <iframe
              className="iframe-widget"
              style={{ margin: 'auto', border: 0, paddingTop: '30px', height: 'calc(100% - 30px)' }}
              width="580px"
              title="widget"
              src={iframeUrl}
            />
          )}
        </Div>
      </Main>
    </>
  );
};

export default BuySellProcess;
