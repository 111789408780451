import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 0 10px;
  padding-top: 15px;
  padding-bottom: 5px;
  display: flex;
  justify-content: space-between;
`;
export const ContainerActions = styled.div`
  padding-right: 1rem;
  display: flex;
  div {
    padding: 3px 5px;
    color: #999999;
    cursor: default;

    &.enabled {
      color: black;
      &:hover {
        background-color: #eeeeee80;
        cursor: pointer;
      }
    }
  }
`;
