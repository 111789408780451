const initialState = {
  authInfo: {},
  loading: false,
  error: false,
};

function authReducer(state, action) {
  let userState;

  switch (action.type) {
    case 'START_UPDATE': {
      return { ...state, authInfo: {}, loading: true, error: false };
    }
    case 'USER_LOGIN': {
      localStorage.setItem('userToken', action.data.token);
      return { ...state, authInfo: action.data.data, loading: false, error: false };
    }
    case 'FAIL_UPDATE': {
      return { ...state, authInfo: {}, loading: false, error: action.problem };
    }
    case 'FAIL_API': {
      return { ...state, authInfo: {}, loading: false, error: action.error };
    }
    case 'START_REGISTER': {
      return { ...state, loading: true, error: false };
    }
    case 'FAIL_REGISTER': {
      return { ...state, loading: false, error: action.problem };
    }
    case 'REGISTER': {
      userState = { ...state, authInfo: action.data.data, loading: false, error: false };
      localStorage.setItem('user', JSON.stringify(userState));
      return userState;
    }
    case 'START_SEND_CODE': {
      return { ...state, loading: true, error: false };
    }
    case 'SEND_CODE': {
      userState = { ...state, authInfo: action.data.data, loading: false, error: false };
      localStorage.setItem('user', JSON.stringify(userState));
      return userState;
    }
    case 'FAIL_SEND_CODE': {
      return { ...state, authInfo: {}, loading: false, error: action.problem };
    }
    case 'START_VALIDATE_CODE': {
      return { ...state, loading: true, error: false };
    }
    case 'VALIDATE_CODE': {
      userState = { ...state, authInfo: action.data.data, loading: false, error: false };
      localStorage.setItem('user', JSON.stringify(userState));
      return userState;
    }
    case 'FAIL_VALIDATE_CODE': {
      return { ...state, loading: false, error: action.problem };
    }
    case 'START_SET_PASSWORD': {
      return { ...state, loading: true, error: false };
    }
    case 'FAIL_SET_PASSWORD': {
      return { ...state, loading: false, error: action.problem };
    }
    case 'SET_PASSWORD': {
      userState = { ...state, authInfo: action.data.data, loading: false, error: false };
      localStorage.setItem('user', JSON.stringify(userState));
      return userState;
    }
    case 'START_RECOVER_PASSWORD': {
      return { ...state, loading: true, error: false };
    }
    case 'FAIL_RECOVER_PASSWORD': {
      return { ...state, loading: false, error: action.problem };
    }
    case 'RECOVER_PASSWORD': {
      userState = { ...state, authInfo: action.data.data, loading: false, error: false };
      localStorage.setItem('user', JSON.stringify(userState));
      return userState;
    }
    case 'USER_INFO_REQUEST': {
      return { ...state, userInfo: {}, loading: true, error: false };
    }
    case 'USER_INFO_SUCCESS': {
      return { ...state, userInfo: action.data.data, loading: false, error: false };
    }
    case 'API_FAIL': {
      return { ...state, authInfo: {}, loading: false, error: action.error };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

export { authReducer, initialState };
