import React from 'react';
import { Api } from '../actions/Api';

const CheckoutStateContext = React.createContext();
const CheckoutDispatchContext = React.createContext();

function checkoutReducer(state, action) {
  switch (action.type) {
    case 'NEW_CHECKOUT_REQUEST': {
      return { ...state, checkoutInfo: {}, loading: true, error: false };
    }
    case 'NEW_CHECKOUT_SUCCESS': {
      return { ...state, checkoutInfo: { ...action.data }, loading: false, error: false };
    }
    case 'NEW_CHECKOUT_FAIL': {
      return { ...state, checkoutInfo: {}, loading: false, error: true };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function CheckoutProvider({ children }) {
  const [state, dispatch] = React.useReducer(checkoutReducer, {
    checkoutInfo: {},
  });
  return (
    <CheckoutStateContext.Provider value={state}>
      <CheckoutDispatchContext.Provider value={dispatch}>{children}</CheckoutDispatchContext.Provider>
    </CheckoutStateContext.Provider>
  );
}

async function createCheckout(dispatch, checkoutData) {
  dispatch({ type: 'NEW_CHECKOUT_REQUEST' });
  try {
    const { data, problem } = await Api.createCheckout(checkoutData);
    if (problem) {
      dispatch({ type: 'NEW_CHECKOUT_FAIL', problem });
    } else {
      dispatch({ type: 'NEW_CHECKOUT_SUCCESS', data });
    }
  } catch (error) {
    dispatch({ type: 'NEW_CHECKOUT_FAIL', error });
  }
}

function useCheckoutState() {
  const context = React.useContext(CheckoutStateContext);
  if (context === undefined) {
    throw new Error('useCheckoutState must be used within a CheckoutProvider');
  }
  return context;
}

function useCheckoutDispatch() {
  const context = React.useContext(CheckoutDispatchContext);
  if (context === undefined) {
    throw new Error('useCheckoutDispatch must be used within a CheckoutProvider');
  }
  return context;
}
export { CheckoutProvider, useCheckoutState, useCheckoutDispatch, createCheckout };
