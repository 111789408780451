import React from 'react';
import { Row, Anchor, Col } from 'atomize';

const AuthFooter = () => {
  return (
    <Row pos={{ xs: 'static', lg: 'static' }} m="1.5rem 0">
      <Col size={{ xs: 6, lg: 2 }}>
        {/* <Anchor href="https://www.google.com" target="_blank" m={{ b: '1rem' }} textSize="caption">
          Términos y condiciones
        </Anchor> */}
      </Col>
      <Col size={{ xs: 6, lg: 2 }}>
        {/* <Anchor href="https://www.google.com" target="_blank" m={{ b: '1rem' }} textSize="caption">
          Política de Privacidad
        </Anchor> */}
      </Col>
      <Col size={{ xs: 6, lg: 2 }}>
        {/* <Anchor href="https://www.google.com" target="_blank" m={{ b: '1rem' }} textSize="caption">
          Límites y comisiones
        </Anchor> */}
      </Col>
      <Col size={{ xs: 6, lg: 6 }} textAlign={{ lg: 'end' }}>
        <Anchor href="https://settlenetwork.com" target="_blank" m={{ b: '1rem' }} textSize="caption">
          Powered by Settle X
        </Anchor>
      </Col>
    </Row>
  );
};

export default AuthFooter;
