import React, { useEffect, useState } from 'react';
import { Image, Input, Col, Div } from 'atomize';
import { useHistory } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import loginIcon from '../../assets/account-password.svg';
import { setPassword as authSetPassword } from '../../actions/auth';
import { useAuthDispatch, useAuthState } from '../../context/AuthContext';
import ButtonPrimary from '../widgets/ButtonPrimary';
import CustomInput from '../commons/CustomInput';
import CustomNotification from '../commons/CustomNotification';
import Text from '../widgets/Text';

const isAValidPassword = /^(?=.*[A-Za-z])(?=.*\d).{8,30}$/;

const SetPassword = ({ t }) => {
  const [canSubmit, setCanSubmit] = useState(false);
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [repeatPasswordError, setRepeatPasswordError] = useState(false);
  const [matchingPasswordError, setMatchPasswordError] = useState(false);
  const [inputErrors, setInputErrors] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const history = useHistory();
  const authDispatch = useAuthDispatch();
  const { authInfo, error, loading } = useAuthState();
  const { email, verificationCodeHash, changePasswordMode } = authInfo;

  useEffect(() => {
    if (!error && submitted && !loading) {
      history.push('/');
    } else if (error && !loading) {
      setSubmitted(false);
    }
  }, [error, loading, submitted]);

  useEffect(() => {
    const popCallback = () => {
      history.push('/auth/set-password');
    };
    window.addEventListener('popstate', popCallback);

    return () => {
      window.removeEventListener('popstate', popCallback);
    };
  }, []);

  const handleNext = async () => {
    if (canSubmit) {
      setSubmitted(true);
      await authSetPassword(authDispatch, { email, verificationCodeHash, changePasswordMode, password });
    }
  };

  useEffect(() => {
    if (password && !isAValidPassword.test(password)) {
      setPasswordError(true);
    } else {
      setPasswordError(false);
    }

    if (repeatPassword && !isAValidPassword.test(repeatPassword)) {
      setRepeatPasswordError(true);
    } else {
      setRepeatPasswordError(false);
    }

    if (password && repeatPassword) {
      if (password !== repeatPassword) {
        setMatchPasswordError(true);
      } else {
        setMatchPasswordError(false);
      }
    }

    if (password && !passwordError && repeatPassword && !repeatPasswordError && !matchingPasswordError) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    }
  }, [password, repeatPassword, passwordError, repeatPasswordError, matchingPasswordError]);

  useEffect(() => {
    const hasErrors = repeatPasswordError || matchingPasswordError;
    setInputErrors(hasErrors);
  }, [repeatPasswordError, matchingPasswordError]);

  return (
    <Col minH={{ xs: 'auto', md: 'calc(100vh - 12rem)' }}>
      <Div w="100%" d="flex" flexDir="column" align="center">
        <Text as="h1" textSize="f20">
          {changePasswordMode === 'sign-up' ? t('account.setPassword.title') : t('account.setPassword.recovery.title')}
        </Text>
        <Image src={loginIcon} w={{ xs: '8rem', md: '6rem' }} m="1rem 0 .5rem" />
        <Div w={{ xs: '90%', md: '60%' }}>
          <Text as="p" fontFamily="secondary" textAlign="center">
            {changePasswordMode === 'sign-up'
              ? t('account.setPassword.subtitle')
              : t('account.setPassword.recovery.subtitle')}
          </Text>
        </Div>
        <Input name="email" value={email} h="3rem" w={{ xs: '15rem', md: '18rem' }} m="1rem 0" disabled bg="#E6E6E7" />
        <CustomInput
          m="0"
          name="password"
          value={password}
          type="password"
          onChange={(e) => setPassword(e.target.value)}
          placeholder={t('account.setPassword.passwordPlaceholder')}
          label={t('account.setPassword.passwordPlaceholder')}
          hasError={passwordError}
          errorMessage={t('account.passwordError')}
        />
        <CustomInput
          m="-.5rem 0 0"
          name="repeatPassword"
          value={repeatPassword}
          type="password"
          onChange={(e) => setRepeatPassword(e.target.value)}
          placeholder={t('account.setPassword.repeatPasswordPlaceholder')}
          label={t('account.setPassword.repeatPasswordPlaceholder')}
          hasError={inputErrors}
          errorMessage={repeatPasswordError ? t('account.passwordError') : t('account.matchingPasswordError')}
        />
        <ButtonPrimary
          type={canSubmit ? 'primary-square-enabled' : 'primary-square-disabled'}
          width={{ xs: '15rem', md: '18rem' }}
          onClick={handleNext}
        >
          {t('global.next')}
        </ButtonPrimary>
      </Div>
      <CustomNotification error={error}>{t('account.setPassword.repeatedPasswordError')}</CustomNotification>
    </Col>
  );
};

export default withTranslation()(SetPassword);
