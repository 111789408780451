import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Div, Text } from 'react-atomize';
import { Dropdown } from 'atomize';
import DropdownOptions from './DropdownOptions';

// here is the form layout
const BuySellForm = ({ menu, t }) => {
  // this state allow show and hidden the menu options
  const [showDropdown, setShowDropdown] = useState(false);

  // this function change the status to true o false when the user make click over the dropdown
  const handleClickDropdown = () => setShowDropdown(!showDropdown);

  return (
    <Div m={{ xs: '1rem 0', md: '2rem 0' }}>
      {/* here is the title of the form */}
      <Text>{t('transactions.buy.wantSpend')}</Text>

      {/* here is the dropdown, */}
      <Dropdown
        // eject the function to changce the status
        onClick={handleClickDropdown}
        // isOpen show the options if this is true and hidde it if is false
        isOpen={showDropdown}
        // here is the menu options
        menu={<DropdownOptions menu={menu} />}
        m="1rem 0"
      >
        {/* here is the coin selectec */}
        Select coin
      </Dropdown>

      {/* here is the value of the coin selected */}
      <Text>≈ 0.00000000 BTC {t('transactions.buy.feeIncluded')}</Text>
    </Div>
  );
};

export default withTranslation()(BuySellForm);
