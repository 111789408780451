const initialState = {
  userInfo: { user: {}, kyc: {}, limits: {} },
  loading: false,
  error: false,
};

function userReducer(state, action) {
  switch (action.type) {
    case 'USER_INFO_REQUEST': {
      return { ...state, loading: true, error: false };
    }
    case 'USER_INFO_SUCCESS': {
      return { ...state, userInfo: action.data.data, loading: false, error: false };
    }
    case 'USER_KYC_REQUEST': {
      return { ...state, loading: true, error: false };
    }
    case 'USER_KYC_SUCCESS': {
      return { ...state, userInfo: { ...state.userInfo, kyc: action.data.data.kyc }, loading: false, error: false };
    }
    case 'ERROR': {
      return { ...state, loading: false, error: true };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

export { userReducer, initialState };
