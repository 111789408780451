import React from 'react';
import ReactDOM from 'react-dom';

// importing stylegron required for atomize
import { Provider as StyletronProvider, DebugEngine } from 'styletron-react';
import { Client as Styletron } from 'styletron-engine-atomic';

import { ThemeProvider as ThemeProviderSC } from 'styled-components';

// for theme setup
import { ThemeProvider, StyleReset } from 'react-atomize';
import reportWebVitals from './reportWebVitals';

import Application from './App';

import { AuthProvider } from './context/AuthContext';

import './styles.css';
import theme from './theme';

// https://www.styletron.org/getting-started#with-react
// eslint-disable-next-line no-void
const debug = process.env.NODE_ENV === 'production' ? void 0 : new DebugEngine();
const engine = new Styletron();

function App() {
  return (
    <ThemeProviderSC theme={theme}>
      <ThemeProvider theme={theme}>
        <StyletronProvider value={engine} debug={debug} debugAfterHydration>
          <StyleReset />
          <link
            href="https://fonts.googleapis.com/css2?family=Mulish:wght@400;500&family=Roboto:wght@400;500&display=swap"
            rel="stylesheet"
          />
          <AuthProvider>
            <Application />
          </AuthProvider>
        </StyletronProvider>
      </ThemeProvider>
    </ThemeProviderSC>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
