import React, { useState, useEffect } from 'react';
import { Div } from 'react-atomize';

// importing components that make up the navigation bar
import Logo from './Logo';
// import User from './User';
import NavLinks from './NavLinks';

import { useUserDispatch } from '../../context/UserContext';
import { getUser } from '../../actions/user';

const Index = () => {
  // for calculate the true viewport height of the navigator and set to the height in mobile version
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  setTimeout(function () {
    window.scrollTo(0, 1);
  }, 0);

  // Get User info for display item menu
  const userDispatch = useUserDispatch();

  useEffect(() => {
    getUser(userDispatch);
  }, []);

  // save the state to show the menu when the user make click on button in navbar (only available in mobile version)
  const [showMenu, setShowMenu] = useState(false);

  // disable the scroll where the menu is visible in mobile
  if (showMenu) {
    const x = window.scrollX;
    const y = window.scrollY;
    window.onscroll = () => window.scrollTo(x, y);
  } else {
    window.onscroll = null;
  }

  // setting the value of flex or none when the state to show the menu change
  let displayMenu;
  if (showMenu) {
    displayMenu = 'flex';
  } else {
    displayMenu = 'none';
  }

  // the function that allow to the icon change the state with a click
  const showMenuFn = () => {
    setShowMenu(!showMenu);
  };

  return (
    <>
      {/* Style of navbar container */}
      <Div bg="white" h={{ xs: 'auto', xl: '100vh' }}>
        {/* Logo component */}
        <Logo displayMenu={displayMenu} showMenuFn={showMenuFn} showMenu={showMenu} />

        {/* This calculates the excess space and uses as height itself and separe their childrens to each extrem */}
        <Div
          bg="white"
          d={{ xs: 'flex', xl: 'flex' }}
          p="1rem 0"
          flexDir="column"
          justify="space-between"
          h={{ xs: 'calc(var(--vh, 1vh) * 100 - 5.5rem)', xl: 'calc(100% - 5.5rem)' }}
          w="100%"
          pos={{ xs: 'absolute', xl: 'relative' }}
          top={{ xs: '5.5rem', xl: '0' }}
          transition
          left={{ xs: showMenu ? '0' : '-2000px', xl: '0' }}
        >
          <Div>
            {/* <User /> */}
            <NavLinks setShowMenu={setShowMenu} />
          </Div>
        </Div>
      </Div>
    </>
  );
};

export default Index;
