import React from 'react';
import { Api } from '../actions/Api';

const KYCStateContext = React.createContext();
const KYCDispatchContext = React.createContext();

function kycReducer(state, action) {
  switch (action.type) {
    case 'GET_KYC_URL': {
      return { ...state, kycInfo: {}, kycUrlRedirect: '', loading: true, error: false };
    }
    case 'GET_KYC_URL_SUCCESS': {
      return { ...state, kycUrlRedirect: action.data.redirect, loading: true, error: false };
    }
    case 'CHECK_KYC': {
      return { ...state, kycInfo: action.data.data, loading: false, error: false };
    }
    case 'FAIL_KYC': {
      return { ...state, kycInfo: {}, loading: false, error: true };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function KYCProvider({ children }) {
  const [state, dispatch] = React.useReducer(kycReducer, {
    kycInfo: {},
    kycUrlRedirect: '',
  });
  return (
    <KYCStateContext.Provider value={state}>
      <KYCDispatchContext.Provider value={dispatch}>{children}</KYCDispatchContext.Provider>
    </KYCStateContext.Provider>
  );
}

async function getRedirectUrl(dispatch, props) {
  dispatch({ type: 'GET_KYC_URL' });
  try {
    const { data, problem } = await Api.getRedirectUrl(props);
    if (problem) {
      dispatch({ type: 'FAIL_KYC', problem });
    } else {
      dispatch({ type: 'GET_KYC_URL_SUCCESS', data });
    }
  } catch (error) {
    dispatch({ type: 'FAIL_KYC', error });
  }
}

function useKYCState() {
  const context = React.useContext(KYCStateContext);
  if (context === undefined) {
    throw new Error('useKYCState must be used within a KYCProvider');
  }
  return context;
}

function useKYCDispatch() {
  const context = React.useContext(KYCDispatchContext);
  if (context === undefined) {
    throw new Error('useKYCDispatch must be used within a KYCProvider');
  }
  return context;
}
export { KYCProvider, useKYCState, useKYCDispatch, getRedirectUrl };
