import { Api } from './Api';

const { api, privateGet, addQueryParams } = Api;

// Api calls get separed in case more tinkering with parameters is needed
// @Api calls
const callUser = () => privateGet(api, addQueryParams('/user/info', {}));

// @Actions
async function getUser(dispatch) {
  dispatch({ type: 'USER_INFO_REQUEST' });
  try {
    const { data, problem } = await callUser();
    if (problem) {
      dispatch({ type: 'ERROR', problem });
    } else {
      dispatch({ type: 'USER_INFO_SUCCESS', data });
    }
  } catch (error) {
    dispatch({ type: 'ERROR', error });
  }
}

async function getKycInfo(dispatch, callback) {
  dispatch({ type: 'USER_KYC_REQUEST' });
  try {
    const { data, problem } = await Api.getKycInfo();
    if (problem) {
      dispatch({ type: 'ERROR', problem });
    } else {
      dispatch({ type: 'USER_KYC_SUCCESS', data });
      if (data.data.kyc.status !== 'Accepted') {
        callback();
      }
    }
  } catch (error) {
    dispatch({ type: 'ERROR', error });
  }
}

export { getUser, getKycInfo };
