import { DefaultTheme } from 'react-atomize';

// setting global styles
const theme = {
  ...DefaultTheme,
  // setting colors
  colors: {
    primary100: '#448AFF',
    primary80: '#69A1FC',
    primary60: '#8FB9FF',
    primary40: '#B4D0FE',
    primary20: '#DAE8FF',
    primary05: '#EEF3FC',

    secondary100: '#4E39E8',
    secondary80: '#6B5BE7',
    secondary60: '#897DE6',
    secondary40: '#A79FE5',
    secondary20: '#C6C2E6',
    secondary05: '#DCDBE4',

    black100: '#070D10',
    black90: '#1F2527',
    black80: '#393D40',
    black70: '#515557',
    black60: '#6A6E70',
    black50: '#838687',
    black40: '#9C9E9F',
    black30: '#B4B6B7',
    black20: '#CDCFCF',
    black10: '#E6E6E7',
    black05: '#F8F9FC',

    success: '#0BB07B',
    error: '#DD2727',
    white: '#FFFFFF',
    warning: '#FECE45',
    transparent: 'rgba(255,255,255,.0)',
  },
  textSize: {
    ...DefaultTheme.textSize,
    f10: '10px',
    f12: '12px',
    f14: '14px',
    f16: '16px',
    f18: '18px',
    f20: '20px',
    f24: '24px',
    f32: '32px',
    f40: '40px',
    f48: '48px',
    f56: '56px',
    f64: '64px',
    f72: '72px',
    f80: '80px',
  },
  // setting font family
  fontFamily: {
    ...DefaultTheme.fontFamily,
    primary: `'Mulish', sans-serif;`,
    secondary: `'Roboto', sans-serif;`,
  },
};

export default theme;
