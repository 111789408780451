import React from 'react';
import { withTranslation } from 'react-i18next';
import { Div, Row, Col } from 'react-atomize';
import Text from '../Text';

const ActivityDetail = ({ title, data, typeActivity, t, m }) => {
  // destructurin each activity value received from data
  const { order, status, received, date, amount, checkout, customStatus } = data;
  const typeTransaction =
    typeActivity === 'deposits'
      ? `${t('activity.purchased')} ${checkout && checkout.destinationAsset}`
      : `${t('activity.sold')} ${checkout && checkout.originAsset}`;
  const transaction = data.transaction || typeTransaction;
  // create an array to render with all values except id and status
  // id will not render is only for key
  // status has a different text align and it has been returned out of the array
  const titles = [date, order, transaction, amount, received];

  // declare variables that will change, colors, alignment, display, status, etc
  let filterBarColor;
  let filterBarWeight;
  let statusText;
  let textSize;
  // let display;

  if (title) {
    filterBarColor = 'black80';
    filterBarWeight = '700';
    textSize = 'f14';
  } else {
    filterBarColor = 'black60';
    filterBarWeight = '500';
    textSize = 'f12';
  }

  if (m === undefined) m = 0;

  switch (customStatus) {
    case 'processed':
      statusText = 'success';
      break;
    case 'pending':
      statusText = 'primary80';
      break;
    case 'rejected':
      statusText = 'error';
      break;
    case 'expired':
      statusText = 'error';
      break;
    default:
      statusText = 'black80';
      break;
  }

  return (
    <Row
      maxW="80rem"
      minW="55rem"
      m={m}
      p=".75rem 0"
      bg="white"
      hoverBg={title ? '' : 'primary05'}
      transition
      d="flex"
      style={{ borderBottom: `1px solid ${title ? '#E2E2E2' : '#F4F6F5'}`, marginBottom: title && '10px' }}
    >
      {/* return all values inside the array with name "arr" this don't have id or status */}
      {titles.map((title, index) => {
        return (
          <Col size="2" key={index}>
            <Text
              fontFamily="secondary"
              md={{ textAlign: 'left' }}
              textSize={textSize}
              fontWeight={filterBarWeight}
              textColor={filterBarColor}
            >
              {title}
            </Text>
          </Col>
        );
      })}

      {/* only return the status value */}
      <Col size="2">
        <Div d="flex" align="center" justify="flex-end">
          <Text fontFamily="secondary" textSize={textSize} fontWeight={filterBarWeight} textColor={statusText}>
            {status}
          </Text>
        </Div>
      </Col>
    </Row>
  );
};

export default withTranslation()(ActivityDetail);
