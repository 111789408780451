import React from 'react';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { Div, Row, Col } from 'react-atomize';

import { UserProvider } from './context/UserContext';
import { ActivityProvider } from './context/ActivityContext';

// importing the i18n
import './i18n';

// // importing the layout page component
import Navbar from './components/navbar/Navbar';
import Home from './components/home/Home';
import Transaccions from './components/transaccions/Transaccions';
import BuySellPage from './pages/BuySellPage';
import Logout from './pages/Logout';
import Activity from './components/activity/Activity';
import Account from './components/account/Account';
import Kyc from './components/kyc';
import AuthPage from './components/auth/AuthPage';

function App() {
  const token = localStorage.getItem('userToken');

  return (
    <ActivityProvider>
      <UserProvider>
        {!token ? (
          <AuthPage />
        ) : (
          <Router>
            <Switch>
              <Div maxW="1920px" m="0 auto" overflow="hidden">
                {/* Use row for separate the navbar with the content of different pages */}
                <Row>
                  {/* navbar always visible in every pages */}
                  <Col size={{ xs: '12', xl: '2' }}>
                    <Navbar />
                  </Col>{' '}
                  {/* components to render according to url */}
                  <Col size={{ xs: '12', xl: '10' }} bg="black05">
                    <Route exact path="/" component={Home} />
                    <Route exact path="/transaccions/:type" component={Transaccions} />
                    <Route exact path="/transactions/new/:type?/:crypto?" component={BuySellPage} />
                    <Route exact path="/kyc/:step" component={Kyc} />
                    <Route exact path="/activity" component={Activity} />
                    <Route exact path="/account" component={Account} />
                    <Route exact path="/logout" component={Logout} />
                  </Col>
                </Row>
              </Div>
            </Switch>
          </Router>
        )}
      </UserProvider>
    </ActivityProvider>
  );
}

export default App;
