import React from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { Div } from 'react-atomize';
import { Anchor } from 'atomize';
import Text from '../widgets/Text';

const LinkItem = ({ link, setShowMenu }) => {
  // desctructuring data link
  const { name, url, icon, disabled } = link;
  const history = useHistory();
  // get the current pathname
  const locationPathname = window.location.pathname;

  // create variables for set status color to different links
  let urlStatus;
  let textStatus;
  let iconStatus;
  const Icon = icon;

  // url who match with pathname get colors status 'primary' else 'default'
  if (disabled) {
    urlStatus = 'transparent';
    textStatus = 'black20';
    iconStatus = '#CCCCCC';
  } else if (locationPathname === url) {
    urlStatus = 'primary100';
    textStatus = 'primary100';
    iconStatus = '#448AFF';
  } else {
    urlStatus = 'transparent';
    textStatus = 'black80';
    iconStatus = '#6A6E70';
  }

  return (
    <>
      {/* anchor only get the url, if set styles sometimes it has error "still I don't now why :)" */}
      <Anchor
        onClick={() => {
          if (!disabled) history.push(url);
          setShowMenu(false);
        }}
      >
        {/* link container, set styles for childrens location */}
        <Div
          m=".5rem"
          p=".5rem"
          transition
          textColor={textStatus}
          bg="white"
          w="100%"
          d="flex"
          justify="flex-start"
          align="center"
          pos="relative"
        >
          {/* icon & name come from navlinks component */}
          <Div m={{ r: '1rem' }} d={{ xs: 'none', lg: 'flex' }} align="center">
            <Icon fill={iconStatus} />
          </Div>

          <Text textSize="f14" textColor={textStatus} p={{ l: { xs: '2rem', lg: '0' } }}>
            {name}
          </Text>

          {/* this is the rectangle who is visible when pathname and url match */}
          <Div w=".25rem" h="100%" bg={urlStatus} pos="absolute" top="0" right="0" d={{ xs: 'none', md: 'block' }} />
        </Div>
      </Anchor>
    </>
  );
};

export default withRouter(LinkItem);
