import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Image, Anchor, Row, Col, Div } from 'atomize';
import emailIcon from '../../assets/email-verify.svg';
import globeIcon from '../../assets/group-3.svg';
import AlignedModal from '../commons/Modal';
import ButtonPrimary from '../widgets/ButtonPrimary';
import { useAuthDispatch, useAuthState } from '../../context/AuthContext';
import { register } from '../../actions/auth';
import DropdownLanguage from './DropdownLanguage';
import SelectCountry from './SelectCountry';
import CustomInput from '../commons/CustomInput';
import CustomNotification from '../commons/CustomNotification';
import Text from '../widgets/Text';

const isAValidEmail = /^[A-Z0-9._+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

const SignUpContainer = ({ t }) => {
  const [email, setEmail] = useState('');
  const [country, setCountry] = useState({ countryId: 0, countryCode: '' });
  const [inputErrors, setInputError] = useState(false);
  const [canSubmit, enableSubmit] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const history = useHistory();

  const authDispatch = useAuthDispatch();

  const { error, loading } = useAuthState();

  useEffect(() => {
    if (!error && submitted && !loading) {
      history.push('/auth/verify-email');
    } else if (error && !loading) {
      setSubmitted(false);
    }
  }, [error, loading, submitted]);

  const handleNext = async () => {
    if (canSubmit) {
      setSubmitted(true);
      await register(authDispatch, { email, countryCode: country.countryCode });
    }
  };

  useEffect(() => {
    console.log(country);
    if (email && !isAValidEmail.test(email)) {
      setInputError(true);
    } else {
      setInputError(false);
    }

    if (email && !inputErrors && country.countryId && country.countryId > 0) {
      enableSubmit(true);
    } else {
      enableSubmit(false);
    }
  }, [email, country, inputErrors]);

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setEmail(email.toLowerCase());
  };

  return (
    <Col minH={{ xs: 'auto', md: 'calc(100vh - 12rem)' }}>
      <Div pos="relative">
        <Text as="h1" textSize="f20" textAlign="center">
          {t('account.signup.title')}
        </Text>

        <Div
          d={{ xs: 'none', md: 'flex' }}
          justify="center"
          align="center"
          pos={{ xs: 'relative', md: 'absolute' }}
          top="-.5rem"
          right={{ md: '2rem' }}
          m={{ xs: 'auto', b: '1rem' }}
        >
          <Image src={globeIcon} w="20px" h="20px" m={{ r: '0.5rem' }} />
          <DropdownLanguage countryId={country.countryId} />
        </Div>
      </Div>
      <Div w="100%" d="flex" flexDir="column" align="center">
        <Image d="block" src={emailIcon} w="7rem" m="1rem 0" />

        <Text as="p" fontFamily="secondary" textAlign="center">
          {t('account.signup.subtitle')}
        </Text>
        <Div w={{ xs: '90%', md: '60%' }} m="1rem 0">
          <Text as="p" fontFamily="secondary" data-i18n="[html]content.body" textAlign="center">
            <strong>{t('account.signup.settleWarning1')}</strong>
            {t('account.signup.settleWarning2')} <AlignedModal title={t('account.signup.settleModal')} />{' '}
            {t('account.signup.settleWarning3')}.
          </Text>
        </Div>

        <Div size="12" d="flex" flexDir="column" align="center">
          <Row>
            <CustomInput
              m="-.25rem 0 .5rem"
              name="email"
              value={email}
              onChange={handleEmailChange}
              placeholder={t('account.signup.emailPlaceholder')}
              label={t('account.signup.emailPlaceholder')}
              hasError={inputErrors}
              errorMessage={t('account.invalidEmailError')}
            />
          </Row>
          <Row w={{ xs: '15rem', md: '18rem' }}>
            <SelectCountry onChange={setCountry} />
          </Row>
          <ButtonPrimary
            type={canSubmit ? 'primary-square-enabled' : 'primary-square-disabled'}
            width={{ xs: '15rem', md: '18rem' }}
            m="1.5rem 0"
            onClick={() => handleNext()}
          >
            {t('global.next')}
          </ButtonPrimary>

          <Anchor href="/auth/login" d="block" m={{ b: '.5rem' }} textAlign="center">
            {t('account.signup.login')}
          </Anchor>
          <Anchor href="/auth/login" d="block">
            {t('global.cancel')}
          </Anchor>
        </Div>
      </Div>
      <CustomNotification error={error}>{t(`authError.${error}`)}</CustomNotification>
    </Col>
  );
};

export default withTranslation()(SignUpContainer);
