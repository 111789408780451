export const container = {
  initial: {
    x: 200,
  },
  animate: {
    x: 0,
  },
  exit: {
    x: 200,
  },
};

export const spring = {
  type: 'spring',
  damping: 10,
  stiffness: 100,
};
