import React from 'react';
import { useHistory } from 'react-router-dom';

const Logout = () => {
  localStorage.removeItem('userToken');
  const history = useHistory();
  history.push('/auth/login');
  history.go(0);
  return <></>;
};

export default Logout;
