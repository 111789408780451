import React from 'react';
import BuySellProcess from '../../components/transaccions/BuySellProcess';
import { CheckoutProvider } from '../../context/CheckoutContext';

const BuySellPage = ({ match }) => (
  <CheckoutProvider>
    <BuySellProcess {...{ match }} />
  </CheckoutProvider>
);

export default BuySellPage;
