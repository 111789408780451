import React from 'react';
import { withTranslation } from 'react-i18next';
import { motion, AnimatePresence } from 'framer-motion';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { Container, Div, Row } from 'atomize';
import { AuthProvider } from '../../context/AuthContext';

import AuthHeader from './AuthHeader';
import AuthFooter from './AuthFooter';
import LogInContainer from './LogInContainer';
import SignUpContainer from './SignUpContainer';
import ResetPassword from './ResetPassword';
import VerifyEmail from './VerifyEmail';
import SetPassword from './SetPassword';
import { container, spring } from './framer/default';

const AuthPage = () => {
  return (
    <AuthProvider>
      <Div w="100vw" h="100%" m="0 auto" bg="#f6f6f6" overflow="auto">
        <Container w={{ md: '59rem' }} maxW="100%" h="auto">
          <AuthHeader />
          <AnimatePresence>
            <motion.div
              transition={spring}
              variants={container}
              initial="initial"
              animate="animate"
              exit="exit"
              style={{ width: '100%' }}
            >
              <Row bg="white" h="auto" align="center" p="1.5rem 0">
                <Router>
                  <Switch>
                    <Route exact path="/">
                      <Redirect to="/auth/login" />
                    </Route>
                    <Route exact path="/auth/login" component={LogInContainer} />
                    <Route exact path="/auth/register" component={SignUpContainer} />
                    <Route exact path="/auth/recover" component={ResetPassword} />
                    <Route exact path="/auth/verify-email" component={VerifyEmail} />
                    <Route exact path="/auth/set-password" component={SetPassword} />
                    <Route exact path="/*">
                      <Redirect to="/auth/login" />
                    </Route>
                  </Switch>
                </Router>
              </Row>
            </motion.div>
          </AnimatePresence>
          <AuthFooter />
        </Container>
      </Div>
    </AuthProvider>
  );
};

export default withTranslation()(AuthPage);
