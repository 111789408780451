import React from 'react';
import { Div } from 'react-atomize';
import { Anchor } from 'atomize';

// this component return the menu options ( coins )
const DropdownOptions = ({ menu }) => {
  return (
    <>
      {/* styles for layout of the menu container */}
      <Div p=".5rem" maxH={{ xs: 'auto', md: '8rem' }} className="overflow-y">
        {/* styles for each option in menu */}
        {menu.map((name) => (
          <Anchor d="block" p=".25rem" m=".25rem" hoverBg="info200">
            {/* here is de value (coin) to select */}
            {name}
          </Anchor>
        ))}
      </Div>
    </>
  );
};

export default DropdownOptions;
