import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { getLastLangFromLocalStorage } from '../i18n';
import { authReducer, initialState } from '../reducers/auth';

const AuthStateContext = createContext();
const AuthDispatchContext = createContext();

function useAuthState() {
  const context = useContext(AuthStateContext);
  if (context === undefined) {
    throw new Error('useAuthState must be used within a AuthProvider');
  }
  return context;
}

function useAuthDispatch() {
  const context = useContext(AuthDispatchContext);
  if (context === undefined) {
    throw new Error('useAuthDispatch must be used within a AuthProvider');
  }
  return context;
}

function AuthProvider({ children }) {
  const user = localStorage.getItem('user');
  const currentState = user ? JSON.parse(user) : initialState;
  const [state, dispatch] = useReducer(authReducer, currentState);
  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(getLastLangFromLocalStorage());
  }, [i18n]);

  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>{children}</AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
}

export { AuthProvider, useAuthState, useAuthDispatch };
