import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Image, Anchor, Col, Div } from 'atomize';
import { useAuthState, useAuthDispatch } from '../../context/AuthContext';
import loginIcon from '../../assets/account-password.svg';
import ButtonPrimary from '../widgets/ButtonPrimary';
import { recoverPassword } from '../../actions/auth';
import CustomInput from '../commons/CustomInput';
import Text from '../widgets/Text';

const isAValidEmail = /^[A-Z0-9._+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

const ResetPassword = ({ t }) => {
  const [email, setEmail] = useState('');
  const [canSubmit, enableSubmit] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const authDispatch = useAuthDispatch();

  const history = useHistory();
  const { error, loading } = useAuthState();

  useEffect(() => {
    if (!error && submitted && !loading) {
      history.push('/auth/verify-email');
    } else if (error && !loading) {
      setSubmitted(false);
    }
  }, [error, loading, submitted]);

  const handleNext = async () => {
    if (canSubmit) {
      setSubmitted(true);
      await recoverPassword(authDispatch, { email });
    }
  };

  useEffect(() => {
    if (email && !isAValidEmail.test(email)) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }

    if (email && !emailError) {
      enableSubmit(true);
    } else {
      enableSubmit(false);
    }
  }, [email, emailError]);

  return (
    <Col minH={{ xs: 'auto', md: 'calc(100vh - 12rem)' }}>
      <Div w="100%" d="flex" flexDir="column" align="center">
        <Text as="h1" textSize="f20" textAlign="center" fontWeight="500">
          {t('account.recovery.title')}
        </Text>
        <Image src={loginIcon} w={{ xs: '5rem', md: '5rem' }} m="1rem 0" />
        <Text as="p" textAlign="center" fontFamily="secondary">
          {t('account.recovery.subtitle')}
        </Text>
        <CustomInput
          m="1rem 0 -1rem"
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder={t('account.recovery.emailPlaceholder')}
          label={t('account.recovery.emailPlaceholder')}
          hasError={emailError}
          errorMessage={t('account.invalidEmailError')}
        />

        <Anchor href="/auth/register" d="block" m="1rem">
          {t('account.create')}
        </Anchor>
        <ButtonPrimary
          type={canSubmit ? 'primary-square-enabled' : 'primary-square-disabled'}
          onClick={handleNext}
          width={{ xs: '15rem', md: '18rem' }}
          m={{ b: '1rem' }}
        >
          {t('global.next')}
        </ButtonPrimary>
      </Div>
    </Col>
  );
};

export default withTranslation()(ResetPassword);
