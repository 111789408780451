import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './lang/en';
import es from './lang/es';
import pt from './lang/pt';
import mx from './lang/mx';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: en,
  },
  es: {
    translation: es,
  },
  pt: {
    translation: pt,
  },
  mx: {
    translation: mx,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'es',

    // keySeparator: true, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export const getLastLangFromLocalStorage = () => window.localStorage.getItem('lang');

export const setCurrentLangToLocalStorage = (lang) => window.localStorage.setItem('lang', lang);

const updateLang = () => {
  const currentLang = getLastLangFromLocalStorage();
  if (!currentLang) {
    setCurrentLangToLocalStorage('es');
  }
};

updateLang();

export default i18n;
