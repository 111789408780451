import { authErrors } from '../error/authError/constants';

export default {
  global: {
    next: 'Next',
    hello: 'Hello',
    change: 'Change',
    limits: 'Limits',
    buy: 'Buy',
    sell: 'Sell',
    cancelOrder: 'Cancel order',
    cancel: 'Cancel',
    total: 'Total',
    fiat: 'ARS',
    thanks: 'Thanks',
    dni: 'ID',
    confirm: 'Confirm',
    accredited: 'Accredited',
    outstanding: 'Outstanding',
    cancelled: 'Canceled',
    or: 'Or',
    cbuText: 'CBU',
    aliasText: 'Alias',
    name: 'Name',
    bank: 'Bank',
    finish: 'Finialize',
    wallet: 'Wallet',
    network: 'Network',
    memo: 'Memo',
    received: 'Received',
    address: 'Address',
    search: 'Search',
    woman: 'Woman',
    man: 'Man',
    processed: 'Processed',
    rejected: 'Rejected',
    expired: 'Expired',
    pending: 'Pending',
    page: 'Page',
    of: 'Of',
  },
  navbar: {
    home: 'Home',
    transactions: 'Buy / Sell',
    activity: 'My activity',
    account: 'My account',
    logOut: 'Log out',
  },
  home: {
    activity: 'My activity',
    buy: 'Buy',
    sell: 'Sell',
    viewAll: 'View all',
    viewLess: 'View less',
    toComplete: 'To complete',
    confirm: 'Confirm',
    requestIncrease: 'Request increase',
    dailyLimit: 'Daily limit remaining',
    annualLimit: 'Annualy limit remaining',
    limits: 'Limits',
    identityVerification: 'Identity verification',
    orderN: 'Order Nº',
    accredited: 'Accredited',
    needConfirmation: 'only your confirmation is missing',
  },
  transactions: {
    next: 'Next',
    message1: 'Price includes payment processor expenses',
    message2: 'By continuing you are accepting the terms and conditions.',
    buy: {
      feeIncluded: 'fees included',
      wantSpend: 'I want to spend',
      selectPayment: {
        title: 'Select a payment method',
        totalPayment: 'Total to pay',
        paymentOptionTransfer: 'Wire transfer',
        paymentOptionCash: 'Cash',
        processorCharge: 'Processor charge',
        message1: 'Your order will be processed by a local payment processor.',
        message2: 'Before continuing you must accept the terms and conditions and privacy policy.',
      },
      orderSumary: {
        title: 'Summary of your payment order',
        cardTitle: 'Your Latamex order',
        checkData: 'Check all the data before continuing',
        totalSalesOrder: 'Total sales order:',
        estimatedCharges: 'Estimated charges:',
        totalPayment: 'Total to pay',
        message1:
          'Collection commission reported by the local payment processor chosen in your jurisdiction. The amount of the commission will be deducted from the total to be received.',
        message2:
          'Your order will be processed by a local payment processor. Before continuing you must accept the terms and conditions and privacy policy.',
      },
      enterWallet: {
        title: 'Destination wallet',
        insertWalletAddress: 'Please enter your wallet address of ',
        toReceive: ' where do you want to receive your cryptocurrencies.',
        placeholderWalletAddress: 'Cryptocurrency wallet address',
        placeholderMemo: 'Memo',
        memoDescription:
          'Many wallets require you to enter a Memo / Tag. In case you are sure that it does not require it, check the option "does not require Memo / Tag". If you complete this option wrongly, your funds may be lost. If you have questions you can read more about Memo / Tag in our help center.',
        memoTagCheckbox: "I don't want MEMO / TAG",
      },
      dataTransfer: {
        title: 'Data for transfer',
        message:
          'Please make a bank transfer from an account in your name, for the exact amount and include the reference number, if your bank allows this option, so that we can better identify your payment. If your bank does not allow you to include the reference number, you can ignore it.',
        cbuText: 'CBU',
        cbuValue: '1500647100064760309123',
        aliasText: 'Alias',
        aliasValue: 'SETTLE.REBANKING.ARS',
        referenceText: 'Reference',
        bankName: 'Banco Industrial',
        headline: 'Headline: Settle AR SAS',
        cuit: 'CUIT: 30-71652016-8',
      },
      endProcess: {
        message1: 'Your order details were received correctly. We send you a detail to your email.',
        message2:
          'When verifying the accreditation of your payment, we will send you an email to confirm your order with the current price.',
        message3:
          'Payments received outside banking hours will be processed when it resumes (Monday to Friday - 10am to 3pm).',
        message4:
          'Your order will be valid for 48 business hours to be paid, once that time has elapsed without receiving payment, the order will be automatically canceled.',
      },
      statusOrder: {
        title: 'Check the status of your order',
        textDoubt: 'If you have doubts about the status of your order',
        linkText: 'contact the support team',
        message1:
          'Enter the order number (check it in the emails we send you) and your ID so you can check if your order is pending, completed or rejected.',
        queryDefault: 'Deposit',
        placeholderOrderNumber: 'Order No.',
        checkOrder: 'Check order',
        orderStatus: 'Order Status',
        queryTypeDeposit: 'Deposit',
        queryTypeextraction: 'Extraction',
      },
      confirmOrder: {
        title: 'Confirm your order No.',
        paymentStatus: 'Status of your payment',
        yourOrder: 'Your order',
        walletAddress: 'Your BTC address',
      },
      finish: {
        your: 'Your',
        viaYourAddress: 'They are on their way to your address',
        thanks: 'Thank you for trading with us',
        createNewOrder: 'Create new order',
        getOut: 'Out',
      },
    },
    sell: {
      feeIncluded: 'fees included',
      wantSpend: 'I want to spend',
      orderSumary: {
        title: 'Summary of your purchase order',
        cardTitle: 'Your Latamex order',
        checkData: 'Check all the data before continuing',
        totalSalesOrder: 'Total sales order:',
        estimatedCharges: 'Estimated charges:',
        message1:
          'Collection commission reported by the local payment processor chosen in your jurisdiction. The amount of the commission will be deducted from the total to be received.',
        totalPayment: 'Total to pay',
        message2:
          'Your payment will be processed by a local payment processor. Before continuing you must accept the terms and conditions and privacy policy',
      },
      addBankAccount: {
        title: 'Add a bank account',
        message1:
          'Please enter the details of a bank account of which you are the owner, to receive payment for your sales order.',
        message2: 'Important: We will not be able to process the transaction if the account is not in your name:',
        cbuText: 'CBU',
        aliasText: 'Alias',
      },
      personalDataBankAccount: {
        title: 'Add a bank account ',
        cuit: 'CUIT/CUIL',
        message1:
          'Please verify your account information, in case your transaction is not correct it cannot be processed. ',
      },
      selectBankAccount: {
        title: 'Select a bank account',
        addAccount: 'Add account',
        message1: 'Please select an account where you will receive the transaction. ',
      },
      dataTransfer: {
        orderNumber: 'Order No.',
        dataTransfer: '- Data for transfer ',
        message1pt1: 'Please make a transfer of ',
        message1pt2: 'to the next wallet. ',
        message1:
          'Important: If your wallet charges an additional withdrawal fee, be sure to add it so we receive the full amount. ',
        message2:
          'Once we receive the transfer in our wallet, we will send a link to your email so that you can confirm your order with the final quote at that moment.',
      },
      orderConfirm: {
        title: 'Confirm your order No. ',
        transferStatus: 'Status of your transfer ',
        txid: 'Tx ID',
        receivedAmount: 'Received amount',
        paymentAmount: 'Amount to collect ',
        paymentCommission: 'Collection commission reported by the payment processor ',
        totalReceive: 'Total to receive ',
        message1:
          'Important: If you do not confirm your order after 72 hours of requesting the confirmation, it will be executed automatically at the final value applicable at that time. ',
      },
      finish: {
        orderComplete: 'Your order was completed! ',
        willReceivePayment: 'You will soon receive the payment in your account ',
        consultOrderStatus: 'Check the status of your order ',
        backToHome: 'Back to top',
        message1: 'Sales orders confirmed outside of banking days and hours will be processed when they resume. ',
      },
    },
  },
  activity: {
    downloadPdf: 'Download PDF of the activity',
    noMovements: 'You still have no movements',
    purchased: 'You purchased',
    sold: 'You sold',
    firstBuy: 'Make your first purchase here',
    firstSell: 'Make your first sale here',
  },
  authError: {
    [authErrors.invalidCredentials]: 'Invalid credentials',
    [authErrors.unavailableForCountry]: 'Settle Wallet is unavailable in your country at the moment.',
    [authErrors.emailAlreadyInUse]: 'Email already in use',
    [authErrors.networkError]: 'Connection Errors',
    [authErrors.serverError]: 'Internal Error',
    [authErrors.unknown]: 'An error occurred. Please try again.',
  },
  account: {
    requestIncrease: 'Request increase',
    identityVerification: 'Identity verification',
    identityVerificationSuccess: 'Completed',
    identityVerificationIncomplete: 'Incomplete',
    identityVerificationPending: 'Pending',
    identityVerificationRejected: 'Rejected',
    create: "Don't have a Settle ID? Create an account",
    passwordError: 'Check your password',
    emailError: 'Check your email',
    invalidEmailError: 'Invalid email',
    matchingPasswordError: 'Passwords do not match',
    limit: {
      deposit: {
        daily: 'Remaining daily purchase limit',
        monthly: 'Remaining monthly purchase limit',
        biannual: 'Remaining semiannual purchase limit',
        anual: 'Remaining annual purchase limit',
      },
      withdraw: {
        daily: 'Remaining daily sale limit',
        monthly: 'Remaining semi-annual sale limit',
        biannual: 'Remaining semi-annual sale limit',
        anual: 'Remaining annual sale limit',
      },
    },
    login: {
      button: 'Enter',
      emailPlaceholder: 'Email',
      passwordPlaceholder: 'Password',
      recovery: 'I forgot my password',
      settleModal: 'What is Settle ID?',
      subtitle: 'Enter with your Settle ID',
      title: 'Enter Latamex with Settle ID',
    },
    recovery: {
      emailPlaceholder: 'Enter your email',
      subtitle: 'Enter your email, you will receive a code to reset your password.',
      title: 'Settle ID - Reset password',
    },
    setPassword: {
      recovery: {
        subtitle:
          'Your new password must have at least 8 characters, a letter, a number and must differ from your previous password.',
        title: 'Settle ID - Create a new password',
      },
      subtitle: 'Your password must have at least 8 characters, a letter and a number.',
      title: 'Settle ID - Create a password',
      passwordPlaceholder: 'Password',
      repeatPasswordPlaceholder: 'Repeat password',
      repeatedPasswordError: 'Password was previously used',
    },
    signup: {
      login: 'Do you already have a Settle ID account? Log in.',
      subtitle: 'To create your account, we will need to verify your email.',
      title: 'Create your Settle ID to enter Latamex',
      settleWarning1: 'Attention',
      settleWarning2: ': Now Latamex requires you to create an account',
      settleWarning3: ', even if you have previously traded with Latamex from an exchange',
      emailPlaceholder: 'Enter your email',
      selectCountryPlaceholder: 'Select a country',
      settleModal: 'Settle ID',
    },
    verifyEmail: {
      recovery: {
        subtitle:
          '  we have received your request. You will receive a code in case the email entered corresponds to a Settle ID account. (Check your spam just in case)',
      },
      fail: 'Incorrect code!',
      resend: 'Forward code',
      resendCodeError: 'Error in sending the code!',
      resendCodeSuccess: 'The code was successfully sent!',
      subtitle1: 'Hello',
      subtitle2:
        ', to continue please enter the 4-digit code that we sent to your email. (Check your spam just in case) ',
      success: 'Correct code!',
      title: 'Settle ID - Verify your email',
    },
  },
  modals: {
    settle: {
      confirm: 'It is understood',
      description1:
        'It is a single account to access different applications, such as Latamex and Stablex, without having to fill in all your data again. Soon you will be able to use it to access other associated applications. ',
      description2:
        "Now access to your Latamex and Stablex account is done through Settle ID. You do not need to do anything new, if you already had an account, your Latamex or Stablex email and password will become your Settle ID. If you don't have an account you will need to register, it will only take a few minutes.",
      question: 'What is Settle ID?',
    },
  },
  kyc: {
    overrideKycTitle: 'ID verification',
    idendityVerification: {
      title: 'Identity verification',
      birthdate: 'Birthdate',
      message1:
        'being the first time that you use Latamex, we will need to verify some of your data, it will only take a few minutes and it will be for the only time.',
    },
    basicData: {
      title: 'Identity verification',
      message1: 'Please enter your name(s), lastname and gender.',
      names: 'Names',
      lastname: 'Lastname',
    },
    address: {
      title: 'Identity verification',
      message1: 'Please enter the address of the address where you currently reside.',
      street: 'Street',
      number: 'Number',
      floor: 'Floor',
      unit: 'Unit',
      selectProvince: 'Select a province',
      city: 'City',
      postalCode: 'Postal code',
    },
    profession: {
      title: 'Identity verification',
      placeholderJob: 'Tell us what you do.',
      placeholderOcupation: 'Ocupation',
      pepCheckbox: "I'm not a P.E.P. (politically exposed person) or obligated subject.",
    },
    phoneVerify: {
      title: 'Enter your phone number',
      message1: 'To finish we need to verify your cell phone number.',
      numberPhone: 'Número de teléfono',
    },
    codeVerify: {
      title: 'Enter your phone number',
      message1: 'Please enter the 4-digit code that we sent to your cell phone.',
      resendCode: 'Resend code',
    },
    confirm: {
      message1:
        'Your information was sent correctly. Our team is beginning the necessary review process as it is the first time you have been dealing with us.',
      message2pt1: 'Stay tuned to your email',
      message2pt2:
        ', once we verify your identity, we will send you a link to your email to continue with the transfer.',
    },
    finish: {
      message1:
        'Your identity was successfully verified. You can continue to obtain the transfer data and finalize your order.',
    },
  },
  widgets: {
    date: 'Date',
    order: 'Order',
    transaction: 'Transaction',
    received: 'Received',
    status: 'Status',
    amount: 'Amount',
    acceptThe: 'I accept the',
    termsAndConditions: 'terms and conditions',
    paymentProcessor: 'from the payment processor.',
    copy: 'copy',
    actualPrice: 'Actual price',
    updating: 'Updating in',
  },
  countries: {
    pick: 'Select a country',
    argentina: 'Argentina',
    brazil: 'Brazil',
    mexico: 'Mexico',
  },
};
