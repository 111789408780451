import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { withTranslation } from 'react-i18next';

import { useKYCState, useKYCDispatch, getRedirectUrl } from '../../context/KycContext';

const KycWebappContainer = ({ onFinish, onCancel, onKycChecked, t }) => {
  const kycDispatch = useKYCDispatch();

  useEffect(() => {
    getRedirectUrl(kycDispatch, {
      hideWelcomeStep: true,
      overrideTitleWith: t('kyc.overrideKycTitle'),
      consumer: process.env.REACT_APP_CONSUMER,
    });
  }, [kycDispatch]);

  const { kycUrlRedirect } = useKYCState();

  useEffect(() => {
    window.addEventListener('message', handleIframeResponse);
  });

  const sourceString = (url) =>
    url.replace('http://', '').replace('https://', '').replace('www.', '').split(/[/?#]/)[0];

  const [iframeHeight, setIframeHeight] = useState(460);

  const handleIframeResponse = (e) => {
    if (kycUrlRedirect && sourceString(e.origin) === sourceString(kycUrlRedirect)) {
      const {
        data: { event, status },
      } = e;
      switch (event) {
        case 'kycFinished':
          onFinish(status);
          break;
        case 'kycCanceled':
          onCancel();
          break;
        case 'kycChecked':
          onKycChecked();
          break;
        case 'heightChanged':
          setIframeHeight(e.data.height > 460 ? e.data.height : 460);
          break;
        case 'callbackFlow':
          onCancel();
          break;
        default:
          console.log(e.data);
      }
    }
  };
  const iframeStyle = {
    height: `${iframeHeight}px`,
  };
  return (
    <React.Fragment>
      {!kycUrlRedirect && (
        <div>
          <Skeleton height={300} width={700} />
        </div>
      )}
      {kycUrlRedirect && (
        <div className="kyc-iframe-container" style={iframeStyle}>
          <iframe
            title="kyc-container"
            src={kycUrlRedirect}
            width="100%"
            height="100%"
            allow="camera;fullscreen"
            allowFullScreen
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default withTranslation()(KycWebappContainer);
