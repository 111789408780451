import styled from 'styled-components';
import { Div } from 'react-atomize';

export const BuySellCards = styled(Div)`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 1rem;

  @media (min-width: 540px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 720px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 994px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1080px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 1196px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;
