import React from 'react';
import { withTranslation } from 'react-i18next';
import { Container, ContainerActions } from './styles';
import Text from '../Text';

const Pagination = ({ currentPage, numberOfPages, totalOfItems, onChangeCurrentPage, tableSize, t }) => {
  const currentPageIsNotTheFirst = currentPage !== 1;
  const currentPageIsNotTheLast = currentPage !== numberOfPages;

  const actions = {
    '⇤': currentPageIsNotTheFirst ? () => onChangeCurrentPage(1) : null,
    '←': currentPageIsNotTheFirst ? () => onChangeCurrentPage(currentPage - 1) : null,
    '→': currentPageIsNotTheLast ? () => onChangeCurrentPage(currentPage + 1) : null,
    '⇥': currentPageIsNotTheLast ? () => onChangeCurrentPage(numberOfPages) : null,
  };

  const countOfItemsFrom = (parseInt(currentPage, 10) - 1) * parseInt(tableSize, 10) + 1;
  let countOfItemsTo = parseInt(currentPage, 10) * parseInt(tableSize, 10);
  if (countOfItemsTo > parseInt(totalOfItems, 10)) countOfItemsTo = parseInt(totalOfItems, 10);

  return (
    <Container>
      {totalOfItems && (
        <Text textSize="f12" textAlign="center" fontFamily="secondary">
          {`${countOfItemsFrom}-${countOfItemsTo} of ${totalOfItems}`}
        </Text>
      )}
      <div className="d-flex flex-row" style={totalOfItems ? null : { flex: 1 }}>
        <Text textSize="f12" textAlign="center" fontFamily="secondary">
          {t(`global.page`)} {currentPage} {t(`global.of`).toLowerCase()} {numberOfPages}
        </Text>
        <div style={totalOfItems ? { width: '20px' } : { flex: 1 }} />
        <ContainerActions>
          {Object.keys(actions).map((key) => (
            <div key={key} onClick={actions[key]} className={`${actions[key] ? 'enabled' : ''}`}>
              {key}
            </div>
          ))}
        </ContainerActions>
      </div>
    </Container>
  );
};

export default withTranslation()(Pagination);
