import React, { useEffect, useState, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { Image, Div, Anchor, Input, Col } from 'atomize';
import { useHistory } from 'react-router-dom';
import Countdown from 'react-countdown';
import emailIcon from '../../assets/email-verify.svg';
import { sendCode, validateCode } from '../../actions/auth';
import { useAuthDispatch, useAuthState } from '../../context/AuthContext';
import ButtonPrimary from '../widgets/ButtonPrimary';
import CustomNotification from '../commons/CustomNotification';
import Text from '../widgets/Text';

const Digit = React.forwardRef(({ value, onChange }, ref) => {
  return (
    <Input
      ref={ref}
      textSize={{ xs: 'heading', md: 'display1' }}
      textAlign="center"
      w="49px"
      h="56px"
      border="solid 1px"
      d="flex"
      value={value}
      onChange={onChange}
      style={{
        MozAppearance: 'textfield',
        appearance: 'textfield',
        WebkitAppearance: 'none',
        margin: 'none',
        '&::WebkitInnerSpinButton&::WebkitOuterSpinButton&::WebkitAppearance': 'none',
      }}
    />
  );
});

const VerifyEmail = ({ t }) => {
  const [firstDigit, setFirstDigit] = useState('');
  const [secondDigit, setSecondDigit] = useState('');
  const [thirdDigit, setThirdDigit] = useState('');
  const [fourthDigit, setFourthDigit] = useState('');
  const secondDigitRef = useRef(null);
  const thirdDigitRef = useRef(null);
  const fourthDigitRef = useRef(null);
  const [canSubmit, enableSubmit] = useState(false);
  const [canSendCode, enableSendCode] = useState(true);
  const [codeSent, setCodeSent] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);
  const [countdown, setCountdown] = useState(null);
  const isNumber = /^[0-9]$/;

  const history = useHistory();

  const authDispatch = useAuthDispatch();

  const { authInfo, error, loading } = useAuthState();
  const { email, checkVerificationCodeMode } = authInfo;

  useEffect(() => {
    if (!countdown) {
      setCountdown(Date.now());
    }
  }, [countdown]);

  useEffect(() => {
    if (!error && submitted && !loading) {
      history.push('/auth/set-password');
    } else if (error && !loading) {
      setSubmitted(false);
    }
  }, [error, loading, submitted]);

  const handleNext = async () => {
    if (canSubmit) {
      setSubmitted(true);
      const verificationCodeHash = firstDigit + secondDigit + thirdDigit + fourthDigit;
      await validateCode(authDispatch, { email, verificationCodeHash, checkVerificationCodeMode });
    }
  };

  const handleOnClickResend = async () => {
    if (canSendCode && !codeSent) {
      setCodeSent(true);
      enableSendCode(false);
      setIsWaiting(true);
      setCountdown(Date.now() + 60 * 1000);
      setFirstDigit('');
      setSecondDigit('');
      setThirdDigit('');
      setFourthDigit('');
      await sendCode(authDispatch, { email, checkVerificationCodeMode });
    }
  };

  const handleOnChangeFirstDigit = (e) => {
    if (isNumber.test(e.target.value)) {
      setFirstDigit(e.target.value);
      secondDigitRef.current.focus();
    } else {
      e.target.value = '';
      setFirstDigit(null);
    }
  };

  const handleOnChangeSecondDigit = (e) => {
    if (isNumber.test(e.target.value)) {
      setSecondDigit(e.target.value);
      thirdDigitRef.current.focus();
    } else {
      e.target.value = '';
      setSecondDigit(null);
    }
  };

  const handleOnChangeThirdDigit = (e) => {
    if (isNumber.test(e.target.value)) {
      setThirdDigit(e.target.value);
      fourthDigitRef.current.focus();
    } else {
      e.target.value = '';
      setThirdDigit(null);
    }
  };

  const handleOnChangeFourthDigit = (e) => {
    if (isNumber.test(e.target.value)) {
      setFourthDigit(e.target.value);
    } else {
      e.target.value = '';
      setFourthDigit(null);
    }
  };

  useEffect(() => {
    enableSubmit(firstDigit && secondDigit && thirdDigit && fourthDigit);
  }, [firstDigit, secondDigit, thirdDigit, fourthDigit]);

  const renderer = ({ seconds, completed }) => {
    if (completed) {
      // Render a completed state
      enableSendCode(true);
      setCodeSent(false);
      setIsWaiting(false);
    }

    // Render a countdown
    if (!canSendCode && isWaiting) {
      return (
        <>
          <p style={{ marginLeft: '1rem' }}>
            {t('account.verifyEmail.resend')} {seconds}&quot;
          </p>
          <br />
          <p style={{ marginLeft: '1rem' }}>{t('account.verifyEmail.resendCodeSuccess')}</p>
        </>
      );
    }

    return '';
  };

  return (
    <Col minH={{ xs: 'auto', md: 'calc(100vh - 12rem)' }}>
      <Div size="10" d="flex" flexDir="column" align="center">
        <Text as="h1" textSize="f20">
          {t('account.verifyEmail.title')}
        </Text>
        <Image src={emailIcon} w="7rem" m="1rem 0 0" />
        <Div w={{ xs: '90%', md: '60%' }}>
          <Text tag="p" fontFamily="secondary" textAlign="center" m="1rem 0">
            {checkVerificationCodeMode === 'sign-up' ? t('account.verifyEmail.subtitle1') : null}
            {''}
            <strong>{email}</strong>
            {checkVerificationCodeMode === 'sign-up'
              ? t('account.verifyEmail.subtitle2')
              : t('account.verifyEmail.recovery.subtitle')}
          </Text>
        </Div>
        <Div m="1rem 0" d="flex" style={{ gridGap: '1rem' }}>
          <Digit value={firstDigit} onChange={handleOnChangeFirstDigit} />
          <Digit ref={secondDigitRef} value={secondDigit} onChange={handleOnChangeSecondDigit} />
          <Digit ref={thirdDigitRef} value={thirdDigit} onChange={handleOnChangeThirdDigit} />
          <Digit ref={fourthDigitRef} value={fourthDigit} onChange={handleOnChangeFourthDigit} />
        </Div>
        <Div m="1rem 0">
          {canSendCode && !codeSent ? (
            <Anchor onClick={handleOnClickResend} d="block">
              {t('account.verifyEmail.resend')}
            </Anchor>
          ) : (
            countdown && (
              <Countdown date={countdown} renderer={renderer} cursor={canSendCode ? 'pointer' : 'not-allowed'} />
            )
          )}
        </Div>
        <ButtonPrimary
          onClick={handleNext}
          type={canSubmit ? 'primary-square-enabled' : 'primary-square-disabled'}
          width={{ xs: '15rem', md: '18rem' }}
        >
          {t('global.next')}
        </ButtonPrimary>
      </Div>
      <CustomNotification error={error}>{t('account.verifyEmail.fail')}</CustomNotification>
    </Col>
  );
};

export default withTranslation()(VerifyEmail);
