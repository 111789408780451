import React from 'react';
// import { Div } from 'react-atomize';
import { withTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { useUserState } from '../../context/UserContext';
import { container, item } from './framer/CardsContainer';

import ListNotification from '../widgets/ListNotification';
import IdentityStatus from './IdentityStatus';
import { moneyMapping } from '../../constant/countryMap';
import { formatMoney } from '../../utils/money';
import { cryptoCurrenciesKey } from '../../constant/crypto';

const CardsContainer = ({ t }) => {
  const {
    userInfo: { user, kyc, limits },
  } = useUserState();

  const { country } = user;
  const {
    _id: id,
    nationalId,
    name,
    lastname,
    gender,
    phoneCountryCode,
    phoneNumber,
    born: birth,
    email,
    status: identity,
  } = kyc;
  const { limitsDeposit, limitsWithdraw, remainingLimitsDeposit, remainingLimitsWithdraw } = limits;

  const dataIdentityStatus = {
    id,
    name: `${name || ''} ${lastname || ''}`,
    gender,
    birth,
    country,
    identity,
    nationalId,
  };

  const countryAsset = moneyMapping[user.countryCode];
  const listLimit = [];

  /* eslint-disable */
  for (const prop in limitsDeposit) {
    if (limitsDeposit[prop]) {
      listLimit.push(
        `${t('account.limit.deposit.' + prop)}  ${countryAsset} ${formatMoney(
          'en-US',
          remainingLimitsDeposit[prop],
          cryptoCurrenciesKey[countryAsset]?.decimal ?? 0
        )}/${formatMoney('en-US', limitsDeposit[prop], cryptoCurrenciesKey[countryAsset]?.decimal ?? 0)}`
      );
    }
  }

  for (const prop in limitsWithdraw) {
    if (limitsWithdraw[prop]) {
      listLimit.push(
        `${t('account.limit.withdraw.' + prop)} ${countryAsset} ${formatMoney(
          'en-US',
          remainingLimitsWithdraw[prop],
          cryptoCurrenciesKey[countryAsset]?.decimal ?? 0
        )}/${formatMoney('en-US', limitsWithdraw[prop], cryptoCurrenciesKey[countryAsset]?.decimal ?? 0)}`
      );
    }
  }
  /* eslint-enable */

  return (
    <motion.div style={{ width: '100%' }} variants={container} initial="hidden" animate="visible">
      <motion.div variants={item}>
        <IdentityStatus dataIdentityStatus={dataIdentityStatus} />
      </motion.div>
      <motion.div variants={item}>
        <ListNotification iconSize="1.5rem" title={email} icon="email.svg" rowCard />
      </motion.div>
      {phoneCountryCode && phoneNumber && (
        <motion.div variants={item}>
          <ListNotification iconSize="1.5rem" title={`${phoneCountryCode} ${phoneNumber}`} icon="phone.svg" rowCard />
        </motion.div>
      )}
      <motion.div variants={item}>
        <ListNotification iconSize="1.5rem" title="● ● ● ● ● ●" icon="lock.svg" rowCard />
      </motion.div>
      <motion.div variants={item}>
        <ListNotification title={t('global.limits')} titleBold message={listLimit} icon="limits.svg" rowCard />
      </motion.div>
      <motion.div variants={item}>
        <ListNotification iconSize="1.5rem" title={country} icon="world.svg" rowCard />
      </motion.div>
    </motion.div>
  );
};

export default withTranslation()(CardsContainer);
