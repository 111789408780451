import React from 'react';
import { Div, Image, Icon } from 'react-atomize';
import { Anchor } from 'atomize';

import FlexColumnCenter from '../widgets/FlexColumnCenter';

// importing logo
const logo = '/assets/brand/logo-latamex-color.svg';

const Logo = ({ displayMenu, showMenuFn }) => {
  let iconName;

  if (displayMenu === 'none') {
    iconName = 'Menu';
  } else {
    iconName = 'Cross';
  }

  return (
    <Div p={{ x: '1rem' }}>
      <FlexColumnCenter flexDir="row" justify={{ xs: 'space-between', xl: 'center' }}>
        <Anchor href="/">
          <Image src={logo} h="3rem" w="auto" m="1rem auto" />
        </Anchor>

        <Div d={{ xs: 'block', xl: 'none' }} cursor="pointer" onClick={showMenuFn}>
          <Icon p="1rem" name={iconName} size="20px" />
        </Div>
      </FlexColumnCenter>
    </Div>
  );
};

export default Logo;
