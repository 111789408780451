import React from 'react';
import { Div } from 'react-atomize';
import BuySellForm from './BuySellForm';

// this is the container for the form, here manage all de logic to pass the values of coins, price, etc.
const BuyFormContainer = () => {
  // next 2 arrays has the options to select in meny dropdown, first for crypto, second for currencies of countries
  const buyOptions = ['BTC', 'ETH', 'BNB'];
  const sellOptions = ['ARS', 'USD', 'MXN', 'BRL'];

  return (
    <Div d="flex" justify="space-around" flexDir={{ xs: 'column', lg: 'row' }} w={{ xs: '100%', lg: '60%' }} m="0 auto">
      {/* here is rendered with data of the 2 arrays declared, each form will be their respective info */}
      {/* maybe, will need to separe the forms and use logics by separated */}
      {/* Put the widget that Julio made :) */}
      {[buyOptions, sellOptions].map((menu) => {
        return <BuySellForm menu={menu} />;
      })}
    </Div>
  );
};

export default BuyFormContainer;
