import React, { useEffect, useState } from 'react';
import { Input, Col, Row } from 'atomize';
import Text from '../widgets/Text';

const CustomInput = ({
  name,
  label,
  placeholder,
  value,
  type = 'text',
  onChange,
  hasError = false,
  errorMessage = '',
  m,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [wasFocused, setWasFocused] = useState(false);

  if (m === undefined) m = { t: '1rem' };

  const changeIfWasFilled = () => {
    if (value && !isFocused && !hasError) {
      setWasFocused(true);
    } else {
      setWasFocused(false);
    }
  };

  useEffect(() => {
    changeIfWasFilled();
  }, [isFocused]);

  return (
    <Row m={m}>
      <Col>
        <Row>
          <Text as="label" textSize="f10" textColor={wasFocused ? 'black20' : 'primary100'}>
            {isFocused || wasFocused ? label : '\u00a0\u00a0'}
          </Text>
        </Row>
        <Row>
          <Input
            name={name}
            value={value}
            type={type}
            onChange={onChange}
            borderColor={isFocused ? '#448AFF' : hasError ? '#DD2727' : '#E6E6E7'}
            focusBorderColor="#448AFF"
            onFocus={() => {
              setIsFocused(true);
            }}
            onBlur={() => {
              setIsFocused(false);
            }}
            placeholder={placeholder}
            h="3rem"
            w={{ xs: '15rem', md: '18rem' }}
            border="1.5px solid"
          />
        </Row>
        <Row>
          <Text as="label" textSize="f10" textColor="error">
            {!isFocused && hasError ? errorMessage : '\u00a0\u00a0'}
          </Text>
        </Row>
      </Col>
    </Row>
  );
};

export default CustomInput;
