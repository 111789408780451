import apisauce from 'apisauce';
import { getAuthError } from '../error/authError/authError';

const privateRequest = ({ api, method, endpoint, params, options, contentType = 'application/json' }) => {
  const token = localStorage.getItem('userToken');
  const headers = options ? options.headers : {};
  return api[method](endpoint, params, {
    ...options,
    headers: {
      ...headers,
      'Content-Type': contentType,
      Authorization: `Token ${token}`,
    },
  });
};

const isSettleIdLogin = (url) => {
  return url === '/settleid/auth/user/login';
};

const privateGet = (api, endpoint, params, options) =>
  privateRequest({ api, method: 'get', endpoint, params, options });

const addQueryParams = (endpoint, params) => {
  return Object.keys(params)
    .reduce((ant, paramKey) => (params[paramKey] ? `${ant}${paramKey}=${params[paramKey]}&` : ant), `${endpoint}?`)
    .slice(0, -1);
};

const privatePost = (api, endpoint, params, options, contentType = 'application/json') =>
  privateRequest({ api, method: 'post', endpoint, params, options, contentType });
//
// const privatePatch = (api, token, endpoint, params, options) =>
//   privateRequest({ api, method: 'patch', endpoint, params, options, token });

const create = (baseURL = '') => {
  const api = apisauce.create({
    baseURL,
    headers: {
      'Cache-Control': 'no-cache',
    },
    timeout: 1000000,
  });

  const publicApi = apisauce.create({
    baseURL,
    headers: {
      'Cache-Control': 'no-cache',
    },
    timeout: 1000000,
  });

  const naviMonitor = (response) => console.log('API MONITOR ACTIVITY:', response);
  api.addMonitor(naviMonitor);
  publicApi.addMonitor(naviMonitor);

  const noSessionMonitor = (response) => {
    if (response.status === 401 && !isSettleIdLogin(response?.config?.url)) {
      window.location.href = '/logout';
    }
  };
  api.addMonitor(noSessionMonitor);

  // Map back errors to front
  api.addResponseTransform((response) => {
    if (response.problem && response.problem !== 'NONE') {
      response.problem = getAuthError(response.problem, response.status);
    }
  });

  const getAllQuotes = (toAsset, pid) => privateGet(api, addQueryParams('/quotes/all', { toAsset, pid }));

  const login = (email, password) => api.post('/auth/account/login', { email, password });

  const activities = {
    getActivities: (typeActivity, consumer, limit, offset, orderBy) =>
      privateGet(api, addQueryParams(`/${typeActivity}/activities`, { consumer, limit, offset, orderBy })),
  };
  const getRedirectUrl = (props) => privatePost(api, '/auth/kyc/create', { props });

  const getUserInfo = () => privateGet(api, addQueryParams('/auth/user/info', {}));

  const getKycInfo = () => privateGet(api, addQueryParams('/user/kyc', {}));

  const createCheckout = (checkoutData) => api.post('/checkout/new', { ...checkoutData, preventRedirect: true });

  return {
    publicApi,
    api,
    privateGet,
    addQueryParams,
    getAllQuotes,
    login,

    activities,
    getRedirectUrl,
    getUserInfo,
    getKycInfo,
    createCheckout,
  };
};

export default {
  create,
};

export const Api = create(process.env.REACT_APP_API_URL);
