export const authErrors = {
  invalidCredentials: 'invalidCredentials',
  unavailableForCountry: 'unavailableForCountry',
  emailAlreadyInUse: 'emailAlreadyInUse',
  serverError: 'serverError',
  networkError: 'networkError',
  unknown: 'unknown',
};

// https://github.com/infinitered/apisauce#problem-codes
export const apiSauceProblemCodes = {
  CLIENT_ERROR: 'CLIENT_ERROR',
  SERVER_ERROR: 'SERVER_ERROR', // 500-599 Any 500 series error.
  TIMEOUT_ERROR: 'TIMEOUT_ERROR', // --- Server didn't respond in time.
  CONNECTION_ERROR: 'CONNECTION_ERROR', // --- Server not available, bad dns.
  NETWORK_ERROR: 'NETWORK_ERROR', //  --- Network not available.
  CANCEL_ERROR: 'CANCEL_ERROR',
};
