import React from 'react';
import { withTranslation } from 'react-i18next';
import Message from '../widgets/Message';

// this component return the messages for transactions layout
const Messages = ({ t }) => {
  // here is the messages to return, all messages always goes inside of an array
  const messages = [`${t('transactions.message1')} (1.5% o min ARS 0).`, `t('transactions.message2')`];

  return (
    <>
      {/* the next component will be rendered for each message of the array */}
      {messages.map((msg, index) => {
        const keyValue = index + 1;
        return <Message msg={msg} key={keyValue} />;
      })}
    </>
  );
};

export default withTranslation()(Messages);
