import React from 'react';
import { Div, Image } from 'react-atomize';
import Link from './Link';
import Text from './Text';

// notification container styles who receive notification as props
const ListNotification = ({ name, url, title, titleBold = false, message = [], icon, rowCard, iconSize }) => {
  // Default the container has align center if the notification just has one message to render, if it has more than one has align from start
  let pb;

  if (message.length > 0) {
    pb = '.5rem';
  } else {
    pb = '0';
  }

  if (iconSize === undefined) {
    iconSize = '2rem';
  }

  return (
    <Div
      bg="white"
      shadow="1"
      p="0 1rem"
      align="center"
      rounded="md"
      style={rowCard ? { marginBottom: '1rem' } : { margin: '1rem 0' }}
    >
      <Div d="flex" p="0 .5rem" align="center">
        <Image
          m={{ r: '1rem', y: '1.5rem' }}
          h={iconSize}
          w="auto"
          style={{ alignSelf: 'flex-start' }}
          src={`assets/illustrations/${icon}`}
        />
        <Div
          d="flex"
          w="100%"
          justify="space-between"
          align={{ xs: 'center', md: 'flex-end' }}
          flexDir={{ xs: 'column', md: 'row' }}
          p={{ y: message ? '2rem' : '1rem' }}
        >
          {/* container alignment title, message */}
          <Div d="flex" flexDir="column" justify="space-between">
            {/* styles for title */}
            <Text fontFamily="secondary" m="-.5rem 0 0" p={`0 0 ${pb} 0`} textColor={titleBold && 'black90'}>
              {title}
            </Text>

            {/* styles for messages */}
            <Div>
              {message &&
                message.map((msg) => {
                  return (
                    <Text fontFamily="secondary" key={msg}>
                      {msg.length ? msg : <span>&nbsp;</span>}
                    </Text>
                  );
                })}
            </Div>
          </Div>

          {/* cta */}
          <Link href={url}>{name}</Link>
        </Div>
      </Div>
    </Div>
  );
};

export default ListNotification;
