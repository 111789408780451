import React from 'react';
import { withTranslation } from 'react-i18next';
import { Div } from 'react-atomize';
import { motion } from 'framer-motion';
import LinkItem from './LinkItem';
import { ReactComponent as Home } from '../../assets/icons/home.svg';
import { ReactComponent as Transactions } from '../../assets/icons/transactions.svg';
import { ReactComponent as Activity } from '../../assets/icons/activity.svg';
import { ReactComponent as Account } from '../../assets/icons/account.svg';
import { ReactComponent as Logout } from '../../assets/icons/logout.svg';
import { container, item } from '../account/framer/CardsContainer';

const NavLinks = ({ t, setShowMenu }) => {
  // these are links who comform the navbar

  const links = [
    { name: t('navbar.home'), icon: Home, url: '/' },
    { name: t('navbar.transactions'), icon: Transactions, url: '/transactions/new' },
    { name: t('navbar.activity'), icon: Activity, url: '/activity' },
    { name: t('navbar.account'), icon: Account, url: '/account' },
  ];

  return (
    <Div m="2rem 0">
      <motion.div style={{ width: '100%' }} variants={container} initial="hidden" animate="visible">
        {/* for each link in array, will render a link */}
        {links.map((link) => {
          return (
            <motion.div variants={item}>
              <LinkItem link={link} key={link.url} setShowMenu={setShowMenu} />
            </motion.div>
          );
        })}
        <motion.div variants={item}>
          <LinkItem link={{ name: t('navbar.logOut'), icon: Logout, url: '/logout' }} />
        </motion.div>
      </motion.div>
    </Div>
  );
};

export default withTranslation()(NavLinks);
